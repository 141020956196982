import * as React from 'react';
import {
  Popover, Box, Card, Typography, Button
} from '@mui/material'

import {Adsense} from '@ctrl/react-adsense';
import ActivePlayersChart from './ActivePlayersChart';
import { useFavorites } from 'assets/favorites';
import { useNavigate } from 'react-router-dom';
import { fetchData } from 'assets/utils';
import { RecentResults } from './RecentResults';

export default () => {

    const favorites = useFavorites();

    return <>

    <Box position="relative" sx={{mt: '25px'}}>   
    <Card elevation={0} sx={{
        pt: 3, pb: 3.5, px: 3, 
        borderRadius: 10, 
        border:  (theme) => '20px solid ' + theme.palette.primary.main,
        
        position: 'absolute',
        transform: 'translate(0, calc(-100% + 0px))',
        width: "100%"
        }}>
    <Typography variant="h6" fontWeight="bold" color="primary" component="div">
    Versie 1.0.5
</Typography>
<Typography variant="body2" component="div" color="text.primary" fontWeight="light">
    26 augustus 2024
</Typography>
    </Card>
    </Box>  

    <Box sx={{paddingTop: '30px', marginBottom: '30px'}}>
        <Adsense
            client="ca-pub-9305958838449958"
            slot="4567492781"
            style={{ display: 'block'}}
            layoutKey='-e5+6h-32-8b+ql'
            layout="in-article"
            format="fluid"
        />
    </Box>

    {favorites.length > 0 ? <FavoritesSection favorites={favorites}/> : null}

    <ActivePlayersChart/>

    <Typography variant="h6" fontWeight="bold" color="text.primary_bold" component="div" sx={{pt: 4}} >
        Wat is er nieuw?
        </Typography>

        <Typography variant="body" color="text.primary_bold" component="div">
            <ul>
                <li>Historiek van klassementen</li>
                <li>Stijlaanpassingen</li>
            </ul>
        </Typography>
</>}

function FavoritesSection({favorites}) {
    return <>
        <Typography variant="h6" fontWeight="bold" color="text.primary_bold" component="div" sx={{pt: 1}} >
        Favorieten
        </Typography>

        {favorites.map(el => <SmallUserCard key={el["userId"]} user={el}/>)}

        <RecentResults favorites={favorites}/>

        
    </>
  }

function SmallUserCard({user}) {
    const navigate = useNavigate();

    const navigateToUser = () => fetchData(user.userId, (content) => {navigate("/speler/" + user.userId, {state: {...content, ts: new Date().getTime()}})})

    return <Button onClick={navigateToUser} disableRipple sx={{ml: -1, px: 1, py: 0, textTransform: "capitalize", display: "flex", width: "100%", justifyContent: "flex-start"}}>
    <Box sx={{py: 1, display: "flex", flexDirection: "column", alignItems: "flex-start"}} >
        <Typography fontSize={16} fontWeight="bold" color="primary" component="div">
            {user.name}
        </Typography>
        <Typography fontSize={12} component="div" color="text.primary">
            {user.club}
        </Typography>
        
    </Box>
    </Button>
} 