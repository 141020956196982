import { Typography } from "@mui/material";

const circColorMap = {50: '#faacee', 100: '#78dbff', 200: '#eb6e74', 300: '#fabe5c', 400: '#b2d62d', 500: '#b0b0b0', 700: '#74cf78', 1000: '#f5b905'}
const NivLogo = ({size, circuit, sx}) => {

    return <Typography display="inline-block" sx={{width: size, height: size, textAlign: "center", lineHeight: (size) + 'px', fontWeight: "bold", borderRadius: size, backgroundColor: circColorMap[circuit], color: "white", fontSize: circuit != 1000 ? Math.round(size/2.5) : Math.round(size/3), mr: 0.5, ...sx}}>
        {circuit}
    </Typography>
}

export default NivLogo;