import * as React from 'react';
import {
    Grid, Card, TextField, Container, Box, InputBase, InputAdornment,
    Typography, Divider,
    CircularProgress,
    CardActionArea,
    Collapse,styled
  } from '@mui/material'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';

const CustomInput = styled(InputBase)({
    backgroundColor: "rgba(255,210,255,0.2)",
    /*'&:hover': {
      backgroundColor: 'rgba(255,255,255,0.4)',
    },*/
    '&.Mui-focused': {
      backgroundColor: 'rgba(255,210,255,0.3)',
    },
  });

export default ({fetchData, fetchList, searchMethod, generalText, listItem, MIN_LENGTH}) => {
    const [text, setText] = React.useState("");
    const [search, setSearch] = React.useState("");
    const textRef = React.useRef("");

    const fullList = React.useRef([]);
    const isFetchingList = React.useRef(false);
    const lastFetchString = React.useRef("");

    const [fetching, setFetching] = React.useState(false);
    //const [userList, setUserList] = React.useState([]);
    const filteredList = search.length >= MIN_LENGTH ? searchMethod(fullList.current, search) : [];


    const nonames_found = search.length >= MIN_LENGTH && filteredList.length == 0;

    const handleSearch = async (e) => {
        const v = e.target.value;
        
        setText(v);
        textRef.current = v;

        const fetch_length = Math.max(MIN_LENGTH, lastFetchString.current.length);

        if (v.length >= MIN_LENGTH) {

            if (lastFetchString.current !== v.slice(0,fetch_length)) { // fetch again 
                
                if (isFetchingList.current == false) {
                    isFetchingList.current = true;
                    // ---
                    try {
                        fullList.current = await fetchList(v);
                        lastFetchString.current = v;
                    } catch (e) {
                        console.error(e);
                    }
                    // ---
                    isFetchingList.current = false;
                    
                    setSearch(textRef.current);
                    return;
                }
            }

            //setUserList(tokenSearch(fullUserList, search));
         
        } else {
            //setUserList([]);
        }
        
        if (isFetchingList.current == false) { // block search set
            setSearch(v);
        }


    }

    const startLoading = async (el) => {
        setFetching(true);
        setSearch(el["name"])
        setText(el["name"])
        try {
            await fetchData(el["id"]);
            
            setSearch("")
            setText("")

        } catch (e) {
          console.error(e);
        }
        setFetching(false);
  
    }

    const [focus, setFocus] = React.useState(false);

    return <>

        <Box sx={{mx: 2}} flexDirection="row">
        <CustomInput
        sx={{ px: 2, py: 2, width: "100%",
        
            input: {
                "&::placeholder": {    // <----- Add this.
                   opacity: focus ? 0.8 : 0.7,
                },
             },
             borderRadius: 2,

             '&.Mui-focused': {
                //boxShadow: 2
             }
             
        
        }}
        placeholder={"Zoek een " + generalText.search}
        inputProps={{style: {color: "white", textTransform: text.length > 0 ? "capitalize" : undefined}}}
        autoComplete='off' 
        value={text} 
        onChange={handleSearch}
        onFocus={() => {setFocus(true)}}
        onBlur={() => {setFocus(false)}}

        startAdornment={
          <InputAdornment position="start">
            <SearchIcon sx={{color: focus ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.7)"}}/>
          </InputAdornment>
        }
        />
        </Box>
        
        <Container maxWidth="sm" sx={{position: "absolute", left: 0, right: 0, zIndex: 100}} ><Card sx={{mt: 3.5, mx: 2, borderRadius: 2}} elevation={2}>

        {fetching ? <Box sx={{ display: 'flex', p: 2,justifyContent: "center" }}><CircularProgress disableShrink={true} /></Box> : <SuggestionList listItem={listItem} list={filteredList} handleClick={startLoading}/>}
        
        {!fetching && nonames_found ? <Typography variant="body2" sx={{p: 2}}>{"Geen " + generalText.search + "s gevonden"}</Typography> : null}
        </Card></Container>
  </>

}

const SuggestionList = ({list, handleClick, listItem, nonames_found, nonames_text}) => {
    const [maxRender, setMaxRender] = React.useState(20);

    if (list == null || (list.length == 0 && !nonames_found)) {
        return null;
    }

    return <>
        {list.filter((item, index) => index < maxRender).map((el, index) => listItem(index, el, handleClick))}

        {list.length <= maxRender? null : 
        <>
        <Divider/>
        <CardActionArea onClick={()=>{setMaxRender(maxRender + 50)}} sx={{pl: 2, pt: 1, pb: 1, pr: 2, justifyContent: "center"}}>
        <Grid container direction="row" alignItems="center" justifyContent="center">
            <MoreHorizIcon color="text.primary" opacity={0.5}/>
        </Grid>
            
        </CardActionArea>
        </>
        }

        
    </>
}