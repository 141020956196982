import * as React from 'react';
import {
  Popover, Container, Divider, Card, CardContent, Box, Typography, CircularProgress, Skeleton
} from '@mui/material'

import UserCard from '../main/components/UserCard';
import CalculationCard from '../main/components/CalculationCard';
import UserSearchCard from '../main/components/UserSearchCard';
import PercentageCard from '../main/components/PercentageCard';
import LeaderboardCard from '../main/components/LeaderboardCard';
import News from 'components/News';
import PageWrapper from 'components/PageWrapper'

import {Adsense} from '@ctrl/react-adsense';
import Pyramid from 'components/Pyramid';
import { useFirstRender, fetchData, fixData, refreshData } from 'assets/utils';
import constants from 'assets/constants';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';

export default function SpelerPage() {
    const location = useLocation();
    const userId = parseInt(useParams()["*"]);
    const [searchParams, setSearchParams] = useSearchParams();

    const valid_state = location.state != null && userId === location.state["user_id"] && new Date().getTime() - location.state["ts"] < 10000;

    const [data, setData] = React.useState(valid_state ? location.state : null);
    const [counter, setCounter] = React.useState(null);
    const fetching = React.useRef(false);
    const refreshing = React.useRef(false)
    const navigate = useNavigate();

    const firstRender = React.useEffect(()=> {
      if (valid_state) {
        setData(location.state)
      } else if (fetching.current == false) {
        fetching.current = true
        fetchData(userId, (result) => {if (result) {setData(result)};fetching.current=false}, searchParams.get("s"));
      }
    }, [userId, searchParams]);

    React.useEffect(() => {
      if (data != null) {
        setCounter(data["counter"])

        if ("refresh_url" in data && refreshing.current == false) {
          refreshing.current = true
          refreshData(data["refresh_url"], (result) => {setData(result);refreshing.current=false}, () => {refreshing.current = false});
        }
      }
    }, [data]);
  
    return <PageWrapper counter={counter} setCounter={setCounter} SearchCard = {()=><UserSearchCard fetchData={(id) => {fetchData(id, (result) => navigate("/speler/" + id, {state: {...result, ts: new Date().getTime()}}))}}/>}>

            <UserCard data={data} sx={{mt: -15}} canSelectSeason={() => !fetching.current && !refreshing.current} />

            {data != null ? <>
            

            <LeaderboardCard data={data} />

            <CalculationCard data={data} handleFix={() => fixData(data["user_id"], setData)} />

          </> : null}

      
      </PageWrapper>
}