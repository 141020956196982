import * as React from 'react';
import constants from 'assets/constants';

const RANK_MALE = [100, 200, 300, 400, 500, 700, 1000];
const RANK_FEMALE = [50, 100, 200, 300, 400, 500, 700];
export function next_rank(rank, is_female) {
    const ar = is_female ? RANK_FEMALE : RANK_MALE;

    for (var i = 0; i < ar.length - 1; i++) {
        if (rank == ar[i]) {
            return ar[i+1];
        }
    }

    return ar[ar.length - 1];
}

export function getMaleRanks() {
    return RANK_MALE;
}

export function getFemaleRanks() {
    return RANK_FEMALE;
}

export function prev_rank(rank, is_female) {
    const ar = is_female ? RANK_FEMALE : RANK_MALE;

    for (var i = 1; i < ar.length; i++) {
        if (rank == ar[i]) {
            return ar[i-1];
        }
    }
    return ar[0];
}

export function useFirstRender() {
    const ref = React.useRef(true);
    const firstRender = ref.current;
    ref.current = false;
    return firstRender;
}

export function scrollTo(offset, callback) {
    const fixedOffset = offset.toFixed();
    const onScroll = function () {
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener('scroll', onScroll)
                callback()
            }
        }

    window.addEventListener('scroll', onScroll)
    onScroll()
    window.scrollTo({
        top: offset,
        behavior: 'smooth'
    })
}

export const refreshData = async (refresh_url, callback, onFail) => {
    const req = await fetch(constants["BACKEND"] + refresh_url);
        
    if (req.status == 200) {
        const data = await req.json();
        callback(data);
    } else {
        onFail()
    }
}

export async function fetchData(userId, callback, season) {
    const data = await fetch(constants["BACKEND"] + "/get_user_report/" + userId + (season ? "?s=" + season : ""));

    const content = await data.json();
    
    //console.log(content);

    callback(content);
}

export async function fixData(userId, callback) {
    const data = await fetch(constants["BACKEND"] + "/fixme/" + userId);


    if (data.status == 200) {
        const content = await data.json();
        callback(content);
    }
}

let fetchChartPromise = null;
let cd = null;
export function useChartData() {

    const [chartData, setChartData] = React.useState(cd ? cd : {});

    if (cd === null) {
        if (fetchChartPromise === null) {
            fetchChartPromise = fetch(constants["BACKEND"] + "/get_charts").then(response => response.json());
            fetchChartPromise.then(data => cd = data);
        }
        fetchChartPromise.then(data => {
            setChartData(data);
        });
    }
    return chartData;
}

export async function getRecentResults(users) {
    const data = await fetch(constants["BACKEND"] + "/recent_results", {
        method: "POST", 
        body: JSON.stringify({users: users}),
        headers: {
            "Content-Type": "application/json",
        }
    }) 
    if (data.status == 200) {
        return await data.json()
    }
    return null
}

export function stripAccents(s) {
    return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
}