import * as React from "react";

const Win = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 452 452"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <title>{"Layer 1"}</title>
      <g id="svg_4" />
      <g id="svg_5" />
      <g id="svg_6" />
      <g id="svg_7" />
      <g id="svg_8" />
      <g id="svg_9" />
      <g id="svg_10" />
      <g id="svg_11" />
      <g id="svg_12" />
      <g id="svg_13" />
      <g id="svg_14" />
      <g id="svg_15" />
      <g id="svg_16" />
      <g id="svg_17" />
      <g id="svg_18" />
      <path
        id="svg_3"
        d="m225.91351,451c-124.30939,0 -225,-100.69061 -225,-225c0,-124.30939 100.69061,-225 225,-225c124.30939,0 225,100.69061 225,225c0,124.30939 -100.69061,225 -225,225z"
        opacity="undefined"
        stroke={props.color === undefined ? "#77DD77" : props.color}
        fill={props.color === undefined ? "#77DD77" : props.color}
      />
      <text
        fontWeight="bold"
        transform="matrix(19.502 0 0 16.0605 -2320.55 -2194.22)"
        stroke="#ffffff"
        xmlSpace="preserve"
        fontFamily="sans-serif"
        textAnchor="start"
        fontSize={15}
        strokeWidth={1}
        id="svg_21"
        y={156.8}
        x={123.5}
        fill="#ffffff"
      >
        {"W"}
      </text>
    </g>
  </svg>
);

const Lose = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 452 452"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <title>{"Layer 1"}</title>
        <g id="svg_4" />
        <g id="svg_5" />
        <g id="svg_6" />
        <g id="svg_7" />
        <g id="svg_8" />
        <g id="svg_9" />
        <g id="svg_10" />
        <g id="svg_11" />
        <g id="svg_12" />
        <g id="svg_13" />
        <g id="svg_14" />
        <g id="svg_15" />
        <g id="svg_16" />
        <g id="svg_17" />
        <g id="svg_18" />
        <path
          id="svg_3"
          d="m225.91351,451c-124.30939,0 -225,-100.69061 -225,-225c0,-124.30939 100.69061,-225 225,-225c124.30939,0 225,100.69061 225,225c0,124.30939 -100.69061,225 -225,225z"
          opacity="undefined"
          stroke={props.color === undefined ? "#FF6961" : props.color}
          fill={props.color === undefined ? "#FF6961" : props.color}
        />
        <text
          fontWeight="bold"
          transform="matrix(19.95 0 0 16.05 -2320.55 -2194.22)"
          stroke="#ffffff"
          xmlSpace="preserve"
          textAnchor="start"
          fontFamily="sans-serif"
          fontSize={15}
          strokeWidth={1}
          id="svg_21"
          y={156.8}
          x={122.5}
          fill="#ffffff"
        >
          {"V"}
        </text>
      </g>
    </svg>
  );

  const Empty = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="-5 -5 462 462"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <title>{"Layer 1"}</title>
        <g id="svg_4" />
        <g id="svg_5" />
        <g id="svg_6" />
        <g id="svg_7" />
        <g id="svg_8" />
        <g id="svg_9" />
        <g id="svg_10" />
        <g id="svg_11" />
        <g id="svg_12" />
        <g id="svg_13" />
        <g id="svg_14" />
        <g id="svg_15" />
        <g id="svg_16" />
        <g id="svg_17" />
        <g id="svg_18" />
        <path
          id="svg_3"
          d="m225.91351,451c-124.30939,0 -225,-100.69061 -225,-225c0,-124.30939 100.69061,-225 225,-225c124.30939,0 225,100.69061 225,225c0,124.30939 -100.69061,225 -225,225z"
          opacity="undefined"
          stroke="#777777"
          fill="transparent"
          strokeWidth="10"
        />
      </g>
    </svg>
  );

export function WinIcon() {
    return <div style={{height: 20, width: 20, marginRight: 3}}>
            <Win/>
        </div>
} 

export function LoseIcon() {
    return <div style={{height: 20, width: 20, marginRight: 3}}>
            <Lose/>
        </div> 
} 

export function WinIconInvalid() {
  return <div style={{height: 20, width: 20, marginRight: 3}}>
          <Win color="#fcba03"/>
      </div>
} 

export function LoseIconInvalid() {
  return <div style={{height: 20, width: 20, marginRight: 3}}>
          <Lose color="#fcba03"/>
      </div> 
} 

const LogoWhite = ({color}) => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="25" height="25" viewBox="0 0 512.000000 512.000000"
  preserveAspectRatio="xMidYMid meet">

  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
  fill={color} stroke="none">
  <path d="M2640 5113 c0 -5 9 -56 19 -114 117 -637 521 -1280 1097 -1745 321
  -260 699 -458 1057 -553 122 -33 296 -65 304 -57 7 7 -15 242 -33 346 -126
  740 -572 1390 -1216 1771 -289 172 -622 289 -950 333 -153 21 -278 30 -278 19z"/>
  <path d="M2170 5090 c-1046 -168 -1874 -939 -2106 -1961 -23 -105 -57 -319
  -51 -326 2 -1 56 -12 121 -23 807 -138 1590 -652 2109 -1383 284 -400 469
  -841 542 -1287 8 -51 16 -94 18 -96 2 -2 52 3 112 11 556 78 1059 329 1455
  725 336 336 574 762 681 1219 20 87 62 341 57 346 -2 2 -59 13 -128 24 -1178
  199 -2283 1226 -2589 2405 -26 99 -71 327 -71 357 0 12 -12 11 -150 -11z"/>
  <path d="M4 2398 c30 -373 106 -656 261 -973 354 -723 1052 -1248 1843 -1385
  122 -21 360 -45 368 -37 8 8 -24 180 -57 302 -100 366 -283 719 -554 1061
  -105 133 -384 409 -520 515 -328 256 -661 430 -1013 530 -91 26 -295 69 -325
  69 -6 0 -7 -33 -3 -82z"/>
  </g>
  </svg>
)

export function EmptyIcon() {
  return <div style={{height: 20, width: 20, marginRight: 3}}>
          <Empty/>
      </div> 
} 

export function LogoWhiteIcon({color}) {

  return <div style={{width: 25, height: 25, marginRight: 5, marginTop: -1}}>
          <LogoWhite color = {color !== undefined ? color : "#FFFFFF"}/>
      </div> 
} 