import * as React from 'react';
import {
  Container, Divider, Card, Box, Typography, Link
} from '@mui/material'

import PageWrapper from 'components/PageWrapper';

export default function ContactPage() {
    
  
    return <PageWrapper>
            <Box position="relative" sx={{mt: '25px'}}>   
            <Card elevation={0} sx={{
                pt: 3, pb: 3.5, px: 3, 
                borderRadius: 10, 
                border:  (theme) => '20px solid ' + theme.palette.primary.main,
                
                position: 'absolute',
                transform: 'translate(0, calc(-100% + 0px))',
                width: "100%"
                }}>
            <Typography variant="h6" fontWeight="bold" color="text.primary_bold" component="div">
                Contact
            </Typography>
            

                <Typography variant="body" color="text.primary_bold" component="div" >
                Bij vragen over de website contacteer ons op: <br/>
                <Link href="mailto:info.tennisstats@gmail.com" underline="none">
                info.tennisstats@gmail.com
                </Link>
                </Typography>
            </Card>
            </Box>  
    </PageWrapper>
}