import { useTheme } from "@mui/material"
import { useId } from "react";

export default function PercentageBar({marks, currentValue, percentage, predictedValue, marksFrom, marksTo}) {
    const theme = useTheme()

    const progressBarY = 24;

    const id = useId()
    const id2 = useId()

    const valueToPositionValue = (value) => {
        return 100 * (value - marksFrom) / (marksTo - marksFrom);
    }

    const valueToPosition = (value) => {
        return valueToPositionValue(value) + "%";
    }


    return <svg style={{width: "100%", marginLeft: '0%', height: "50px", overflow: 'visible', padding: '0px 24px', marginTop: '12px', marginBottom: '12px'}}>
        
        <defs>
            <clipPath id={id}>
            <rect id="clipRect" x="0%" y={progressBarY + "px"} width={valueToPosition(predictedValue)} height="14px" rx={2} />
            </clipPath>
            <clipPath id={id2}>
            <rect id="clipRect" x="0%" y={progressBarY + "px"} width={valueToPosition(Math.min(currentValue, predictedValue))} height="14px" />
            </clipPath>
        </defs>

        <rect rx="7px" width="100%" y={progressBarY + "px"} height="14px" fill="#e0e0e0" />
        <rect rx="7px" y={progressBarY + "px"}
                width="100%"
                height="14px"
                clip-path={`url(#${id})`}
                fill={theme.palette.primary.main}
                />
        <rect rx="7px" width="100%" y={progressBarY + "px"} height="14px" fill={theme.palette.primary.dark} clip-path={`url(#${id2})`} />


        {marks.map((el, index) => <line key={el.index} x1={valueToPosition(el.value)} y1={progressBarY + "px"} x2={valueToPosition(el.value)} y2={progressBarY + 14 + "px"} stroke={"white"} strokeWidth="1" />)}
        {marks.map((el, index) => el.label && (marks.length < 6 || index % 2 == 0) ? <BorderedLabel key={el.index} x={valueToPosition(el.value)} y="13px" text={el.label} color={el.color} opacity={el.value <= predictedValue ? 1 : 0.7} /> : null)}
        {marks.map((el, index) => el.points ? <text key={el.index} x={valueToPosition(el.value)} y="42px" dominant-baseline="hanging" text-anchor="middle" fontWeight="normal" fontSize={10} fill={theme.palette.primary.dark} style={{transform: "translateX(2px)"}}>{el.points}</text>: null)}

        {percentage >= 15 ? 
            <text x={Math.min(98, valueToPositionValue(predictedValue) - 1) + "%"} y="32px" dominant-baseline="middle" text-anchor="end" fontWeight="bold" fontSize={10} fill={"white"}>{Math.round(percentage)}%</text> : 
            <text x={Math.max(2, valueToPositionValue(predictedValue) + 1) + "%"} y="32px" dominant-baseline="middle" text-anchor="start" fontWeight="bold" fontSize={10} fill={theme.palette.primary.main}>{Math.round(percentage)}%</text>
        }


        </svg>
}

function BorderedLabel({x, y, text, color, opacity, stroke}) {
    const theme = useTheme();

    return <svg x={x} y={y} overflow="visible">
        <rect x="-25px" y="-10px" width="50px" height="20px" opacity={opacity} rx={5} fill={color ? color : theme.palette.primary.main} stroke={stroke} strokeWidth={1}/>
        <text x="0px" y="1px" dominant-baseline="middle" text-anchor="middle" fontWeight="bold" fontSize={12} fill="white">{text}</text>
    </svg>
}

export function RankLabel({text, color, stroke}) {
    return <svg width="54px" height="24px">
        <BorderedLabel x="27" y="12" text={text} color={color} stroke={stroke} />
    </svg>
}