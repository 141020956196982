import * as React from 'react';
import {
    Typography, AccordionSummary, AccordionDetails, Stack, Divider, Card, CardContent, IconButton, Grid, Accordion,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Popover, Link, Box
} from '@mui/material'

import { LeaderboardLine } from 'pages/leaderboard/leaderboard';
import constants from 'assets/constants';

const leaderboards = constants["LEADERBOARD"]

export default ({data}) => {

    if (data["leaderboard"].length == 0) {
      return null;
    }

    return  <>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{pt: 3}}
    >
      <Typography variant="h6" fontWeight="bold" color="text.primary_bold" component="div">
        Ranglijsten
      </Typography>
    </Stack>

          {data["leaderboard"].sort((a,b) => a["rank"] - b["rank"]).map(el => {
            const filtered = leaderboards.filter(el2 => el2.tag == el["leaderboard"])
          return filtered.length > 0 ? <Card key={el["leaderboard"]} sx={{mt: 2, borderRadius: 2, border: (theme) => '1px solid ' + theme.palette.primary.main}} elevation={0}>
                <Box backgroundColor="primary.main" sx={{py: 2, px: 2}}>
                    <Typography color="common.white" variant="body" fontWeight="bold">{filtered[0].name}</Typography>
                </Box>
                <LeaderboardLine dense={true} rank={el["rank"]+1} id={el["id"]} title={data["name"]} subtitle={data["club"]} value={el["value"]}/>
          </Card> : null })}
      
    </>
}