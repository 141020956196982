import * as React from 'react';
import {Box, Typography, Card, Stack, useTheme, IconButton, Collapse} from '@mui/material';
import { next_rank } from 'assets/utils';
import PercentageBar from 'components/PercentageBar';
import { RankLabel } from 'components/PercentageBar';
import PointsIcon from 'components/PointsIcon';
import SeriesIcon from 'components/SeriesIcon';
import InfoIcon from '@mui/icons-material/Info';

export function CircuitPredictionCard({circuit, isFemale, data, currentRank}) {
    const theme = useTheme();

    const marks = [];

    if (data["mi"] && data["mi"] > 0) {
        marks.push({
            rank: circuit,
            value: data["mi"],
            label: "P" + circuit, 
            points: Math.round(100*data["mi"]) + "%",
            color: circuit <= currentRank ? theme.palette.primary.dark : theme.palette.primary.main
        })
    }

    if (data["ma"] && data["ma"] < 1) {
        const nextRank = next_rank(circuit, isFemale);

        marks.push({
            rank: nextRank,
            value: data["ma"],
            label: "P" + nextRank, 
            points: Math.round(100*data["ma"]) + "%",
            color: nextRank <= currentRank ? theme.palette.primary.dark : theme.palette.primary.main
        })
    }

    const [open, setOpen] = React.useState(false);

    return <Box sx={{pt: 2, opacity: data.v ? 1 : 0.5}}><Card sx={{borderRadius: 2, border: (theme) => '1px solid ' + theme.palette.primary.main}} elevation={0}>
    <Box sx={{pl: 2, backgroundColor: "primary.main", height: 60, display: "flex"}} flexDirection="column" justifyContent="center">
        <Stack flexDirection="row" alignItems="center">
            <Typography variant="body" color="common.white" sx={{fontWeight: "bold", mr: 1}}>
                Berekening 
            </Typography>
            
            <RankLabel text={"P" + circuit} color={theme.palette.primary.primary} stroke="white" />
            {/*<SeriesIcon prefix="P" circuit={circuit} />*/}

            <IconButton>
                <InfoIcon style={{color: "white", opacity: 0.8}} fontSize='medium' onClick={() => setOpen(!open)}/>
            </IconButton>
        </Stack>
    </Box>
    <Box sx={{display: "flex", justifyContent: "center"}}>
        <PercentageBar 
        marks={marks}
        percentage={100 * data.ratio}
        currentValue={circuit < currentRank ? data["ma"] : (circuit == currentRank ? data["mi"] : 0)}
        predictedValue={data.ratio}
        marksFrom={0}
        marksTo={1}
    />
    </Box>
    <Box sx={{display: "flex", justifyContent: "flex-end", mr: '19px', mb: 1}}>
        <PointsIcon points={data.wp} isWp />
        <PointsIcon points={data.vp} />
    </Box>

    <AdditionalInfo data={data} open={open} />
</Card>
</Box>
}

function round2(v) {
    return Math.round(100*v)/100;
}

function AdditionalInfo({data, open}) {
    return <Collapse in={open}>
      <Box sx={{color: "white", backgroundColor: "primary.main", px: 1.5, py: 1}}>
        <Typography sx={{fontWeight: "bold", fontSize: 12}} component="div">
            <Typography sx={{width: 110, display: "inline-block", fontWeight: "inherit", fontSize: 'inherit'}}>Aantal wedstrijden</Typography> {data["nm"]}
        </Typography>
        <Typography sx={{fontWeight: "bold", fontSize: 12}} component="div">
            <Typography sx={{width: 20, display: "inline-block", fontWeight: "inherit", fontSize: 'inherit'}}>WP</Typography> {round2(data["wp"])} = {data["wpb"].map((el, index) => index > 0 ? (el >= 0 ? " + " : " - ") + round2(Math.abs(el)) : round2(Math.abs(el)))}
        </Typography>
        <Typography sx={{fontWeight: "bold", fontSize: 12}} component="div">
            <Typography sx={{width: 20, display: "inline-block", fontWeight: "inherit", fontSize: 'inherit'}}>VP</Typography> {round2(data["vp"])} = {data["vpb"].map((el, index) => index > 0 ? (el >= 0 ? " + " : " - ") + round2(Math.abs(el)) : round2(Math.abs(el)))}
        </Typography>
        <Typography sx={{fontWeight: "bold", fontSize: 12}} component="div">
            <Typography sx={{width: 35, display: "inline-block", fontWeight: "inherit", fontSize: 'inherit'}}>Ratio</Typography> {round2(data["wp"])} / ({round2(data["wp"])} + {round2(data["vp"])}) = {round2(100*data["ratio"])}%
        </Typography>
      </Box>
      </Collapse>
}