import * as React from 'react';
import {
    Grid, Card, TextField, CardContent, Box, 
    Typography, Divider,
    CircularProgress,
    CardActionArea,
    useTheme,
  } from '@mui/material'

import SearchCard from "components/SearchCard";
import constants from 'assets/constants';
import { stripAccents } from 'assets/utils';

async function fetchUserList(s) {
    const data = await fetch(constants["BACKEND"] + "/list_users?s=" + s);
    const content = await data.json();
    
    return content;
}

function tokenSearch(list, s) {
    const tokens = s.split(" ");

    if (list === undefined || list == null) {
        return []
    }

    return list.filter(user => {
        const n = stripAccents(user["name"]);
        const c = stripAccents(user["name_club"].replace(".", ""));

        for (var i = 0; i < tokens.length; ++i) {
            const token = tokens[i];
            
            if (!n.includes(stripAccents(token)) && !c.includes(stripAccents(token))) {
                return false;
            }
        }

        return true;
    })
}

export default function UserSearchCard ({fetchData}) {

    const theme = useTheme();

    return <SearchCard
        fetchData={fetchData}
        fetchList={fetchUserList}
        searchMethod={tokenSearch}
        MIN_LENGTH = {5}
        generalText={{"search": "speler via naam of club", "searchField": "Naam"}}
        listItem={(index, el, handleClick) => 
            <React.Fragment key={el["name"] + el["club_id"] + el["id"]}>
            {index > 0? <Divider/> : null}
            <CardActionArea onClick={()=>{handleClick(el)}} sx={{pl: 2, pt: 1, pb: 1}}>
            <Typography variant="body2" sx={{fontWeight: "bold"}}>{el["name"]}</Typography>
            <Typography variant="caption">{el["padel"] !== undefined ? <><b style={{color: theme.palette.primary.main}}>P{el["padel"]}</b><b> • </b></> : null}{el["name_club"]}</Typography>
            </CardActionArea>

            </React.Fragment>
        }
    />
}