import * as React from 'react'
import {
    Card, TextField, Button, Box, IconButton, Grid, 
    Typography, Popover, Divider, Skeleton, Stack,
    useTheme
  } from '@mui/material'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import PercentageCard from './PercentageCard';
import {next_rank, prev_rank} from 'assets/utils';
import CircuitLogo from 'components/CircuitLogo';
import {Adsense} from '@ctrl/react-adsense';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import { RankLabel } from 'components/PercentageBar';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

import { useAddFavorite, indexOf, useFavorites, useRemoveFavorite } from 'assets/favorites';
import SeasonBar from 'components/SeasonBar';
import { useSearchParams } from 'react-router-dom';
import HistoryChart from './HistoryChart';

export default ({data, sx, canSelectSeason}) => {

    const isLoading = data === null

    return <>

        <Box position="relative" sx={{mt: '25px'}}>   
            <Card elevation={0} sx={{
                pt: 3, pb: 3, px: 3, 
                borderRadius: 10, 
                border:  (theme) => '20px solid ' + theme.palette.primary.main,
                
                position: 'absolute',
                transform: 'translate(0, calc(-100% + 0px))',
                width: "100%", 
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row"
                }}>

            <Box>
            <Typography variant="h6" fontWeight="bold" color="primary" component="div">
            {isLoading ? <Skeleton width="50%"/> : data["name"]}
            </Typography>
            <Typography variant="body2" component="div" color="text.primary" fontWeight="light" sx={{pb: 0.5}}>
            {isLoading ? <Skeleton width="50%"/> : data["club"]}
            </Typography>
            </Box>
            
            {isLoading ? null: <FavoriteButton userId={data["user_id"]} name={data["name"]} club={data["club"]}/>}

            </Card>

            </Box>  

            {data ? <PredictionCard data={data} canSelectSeason={canSelectSeason}/> : null}

    </>
}

function FavoriteButton({userId, name, club}) {

    const favorites = useFavorites();

    const [favorite, setFavorite] = React.useState(indexOf(favorites, userId) >= 0);
    const addFavorite = useAddFavorite();
    const removeFavorite = useRemoveFavorite();

    React.useEffect(() => {
        setFavorite(indexOf(favorites, userId) >= 0)
    }, [userId])

    const toggleFavorite = () => {
        setFavorite(!favorite)
        if (favorite) {
            removeFavorite(userId)
        } else {
            addFavorite({userId: userId, name: name, club: club})
        }
        
    }

    return <IconButton display="flex" onClick={toggleFavorite}>
        {favorite ? <StarIcon color="primary" fontSize='medium'/> : <StarOutlineIcon color="primary" fontSize='medium'/>}
    </IconButton>
}

function PredictionCard({data, canSelectSeason}) {
    const theme = useTheme();

    const current = data.padel.current_rank;
    const predicted = data.padel.predicted_rank;
    const isFemale = data.gender == "V";

    const firstMark = prev_rank(Math.min(current, predicted), isFemale);
    const maxRank = Math.max(current, predicted);
    const lastMark = next_rank(maxRank, isFemale);

    return <Box sx={{pt: 2}}>

<       Box sx={{paddingTop: '30px', marginBottom: '30px'}}>
            <Adsense
              client="ca-pub-9305958838449958"
              slot="4567492781"
              style={{ display: 'block'}}
              layoutKey='-e5+6h-32-8b+ql'
              layout="in-article"
              format="fluid"
            />
            </Box>
        
    <SeasonSelector canSelectSeason={canSelectSeason}/>

    <Card sx={{borderRadius: 2, border: (theme) => '1px solid ' + theme.palette.primary.main}} elevation={0}>    
    <Box sx={{pl: 2, backgroundColor: "primary.main", height: 60, display: "flex"}} flexDirection="column" justifyContent="center">
        <Stack flexDirection="row" alignItems="center">
            <Typography variant="body" color="common.white" sx={{fontWeight: "bold", mr: 1}}>
                Voorspelling: 
            </Typography>
            
            <RankLabel text={"P" + current} color={theme.palette.primary.primary} stroke="white" />
            {current != predicted ? [<TrendingFlatIcon style={{color: "white"}}/>,
            <RankLabel text={"P" + predicted} color={theme.palette.primary.primary} stroke="white" />] : null}
        </Stack>
    </Box>
    <Box sx={{display: "flex", justifyContent: "center"}}>
    <PercentageCard data={data["padel"]} firstMark={firstMark} lastMark={lastMark} isFemale={data["gender"] == "V"} isRightEdge={maxRank == lastMark}/>
    </Box>
</Card>
    
    {data.history.length > 0 ? <>
    <Typography variant="h6" fontWeight="bold" color="text.primary_bold" component="div" sx={{mt: 4}}>
        Klassementen
    </Typography>
    <HistoryChart history={data.history} />
    </> : null}
</Box>
}

function SeasonSelector({canSelectSeason}) {
    const seasons = [
        {tag: "dec2022", label: "Winterberekening 2023", start: 2022.5},
        {tag: "jun2023", label: "Zomerberekening 2023", start: 2023},
        {tag: "dec2023", label: "Winterberekening 2024", start: 2023.5},
        {tag: "jun2024", label: "Zomerberekening 2024", start: 2024},
    ]
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [selected, setSelected] = React.useState(seasons.length - 1);

    React.useEffect(() => {
        const s = searchParams.get("s")

        if (s) {
            var ind = seasons.length - 1;
            for (var i = 0; i < seasons.length; ++i) {
                if (seasons[i].tag == s) {
                    ind = i;
                    break
                }
            }
            setSelected(ind)
        } else {
            setSelected(seasons.length - 1)
        }
    }, [searchParams])

    const select = (i) => {
        if (canSelectSeason()) {
            const newIndex = Math.min(Math.max(i, 0), seasons.length - 1);
            setSearchParams({s: seasons[newIndex].tag})
        }
        
    }

    const marksFrom = 2022.5;
    const marksTo = 2024.5;
    
    const marks = [
        {value: 2023, label: "2023"},
        {value: 2023.5, label: null},
        {value: 2024, label: "2024"},
        {value: 2024.5, label: null},
    ]

    return [
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>

        <Button
            size="small"
            onClick={() => select(selected - 1)}
            style={{visibility: selected == 0 ? 'hidden' : 'visible'}}
          >
            <KeyboardArrowLeft /> 
          </Button>

        <Typography sx={{fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", userSelect: 'none'}} color="primary.main">
            {seasons[selected].label}
        </Typography>

        <Button
            size="small"
            onClick={() => select(selected + 1)}
            //disabled={selected == seasons.length - 1}
            style={{visibility: selected == seasons.length - 1 ? 'hidden' : 'visible'}}
          >
            <KeyboardArrowRight />
          </Button>
    </Box>,
    <SeasonBar marks={marks} from={marksFrom} to={marksTo} selectedFrom={seasons[selected].start} selectedTo={seasons[selected].start+0.5}/>]
}