
const leaderboard = [
    {tag: "padel_t", name: "Aantal gespeelde padeltornooien", name_short: "Padeltornooien", content: null, lb_type: 0},
    {tag: "padel_f", name: "Aantal gewonnen padelfinales", name_short: "Finales", content: null, lb_type: 0},
    {tag: "club_f", name: "Aantal gewonnen padelfinales door de club", name_short: "Clubfinales", content: null, lb_type: 1},
    {tag: "club_t", name: "Aantal gespeelde padeltornooien door de club", name_short: "Clubtornooien", content: null, lb_type: 1},
]

const constants = {
    "BACKEND": "https://padelstats.be/api",
    //"BACKEND": "http://localhost:5001/api",
    "LEADERBOARD": leaderboard,
    "SHOW_ADS": false,
    "FILTERS": {
        "CATEGORY": {"Mannen": "M", "Vrouwen": "V", "Meisjes 13": "V13", "Jongens 13": "M13", "Meisjes 11": "V11", "Jongens 11": "M11", "Jeugd 09": "U09"},
        "RANK": {"P50": 50, "P100": 100, "P200": 200, "P300": 300, "P400": 400, "P500": 500, "P700": 700, "P1000": 1000}
    }
}

export default constants;