import { useTheme } from "@mui/material";

function getCoordinateX(radius, angle) {
    return radius * Math.sin(angle * Math.PI / 180);
}

function getCoordinateY(radius, angle) {
    return -radius * Math.cos(angle * Math.PI / 180);
}

function Arc({cx, cy, radius, start, end, color, value, textColor}) {
    const x1 = cx + getCoordinateX(radius, start);
    const y1 = cy + getCoordinateY(radius, start);
    const x2 = cx + getCoordinateX(radius, end);
    const y2 = cy + getCoordinateY(radius, end);

    const x3 = cx + getCoordinateX(1.4 * radius, (start + end)/2);
    const y3 = cy + getCoordinateY(1.4 * radius, (start + end)/2);

    const largeArcFlag = end - start <= 180 ? 0 : 1;

    if (end - start > 345) {
        return <Donut cx={cx} cy={cy} radius={radius} color={color} />
    }

    return <g>
        <rect x={x1-4} y={y1-5} width={8} height={10} rx={4} transform={`rotate(${start-5},${x1},${y1})`} fill={color} />
        <rect x={x2-4} y={y2-5} width={8} height={10} rx={4} transform={`rotate(${end+5},${x2},${y2})`} fill={color} />
        <path d={`M ${x1} ${y1} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2}`} fill="none" stroke={color} stroke-width="10" />

        {/*<text x={x3} y={y3} dominant-baseline="middle" text-anchor="middle" fontWeight="bold" fontSize={16} fill={textColor}>{value}</text>*/}
    </g>
}

function Donut({cx, cy, radius, color}) {
    return <circle cx={cx} cy={cy} r={radius} fill="none" stroke={color} strokeWidth="10" />
}

export default function StatisticCircle({statistic, left, right, ...props}) {

    const radius = 45;
    const cx = 80;
    const cy = 70;

    const margin = 6

    const percentage = left + right > 0 ? 100 * left / (left + right) : null;
    const adjustedPercentage = percentage !== null ? percentage : 50;

    const angle = 360-adjustedPercentage/100 * 360;

    const theme = useTheme();

    return <svg viewBox="0 0 160 160" overflow="visible" {...props}>

        {angle > 15 ? <Arc cx={cx} cy={cy} radius={radius} start={margin} end={angle - margin} color="#e0e0e0" value={left} textColor="a0a0a0" /> : null}
        {angle < 345 ? <Arc cx={cx} cy={cy} radius={radius} start={angle + margin} end={360 - margin} color={theme.palette.primary.main} value={right} textColor={theme.palette.primary.main}/> : null}

        {percentage !== null ? <text x={cx} y={cy+2.4} dominant-baseline="middle" text-anchor="middle" fontWeight="bold" fontSize={24} fill={theme.palette.primary.main}>{Math.round(100 * left / (left + right))}%</text> : null}

        <text x={cx} y={140} dominant-baseline="middle" text-anchor="middle" fontWeight="bold" fontSize={14} fill={theme.palette.text.primary_bold}>{statistic}</text>
        
        <text x={cx-58} y={cy+2.2} dominant-baseline="middle" text-anchor="end" fontWeight="bold" fontSize={22} fill={theme.palette.primary.main}>{left}</text>
        <text x={cx+58} y={cy+2.2} dominant-baseline="middle" text-anchor="start" fontWeight="bold" fontSize={22} fill="#a0a0a0">{right}</text>

    
    </svg>
}