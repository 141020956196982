import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleIcon from '@mui/icons-material/People';
import { Typography, Box } from '@mui/material';

export default function ParticipantsIcon({participants, color}) {

    return <Typography variant='caption' color="text.secondary">
        {participants}
        <PeopleIcon style={{ fontSize: 16, marginLeft: "1px", verticalAlign:"middle", translate: "0px -1px" }} sx={{color: "#999"}} />
    </Typography>

    return <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant='caption' color="text.secondary">{participants}</Typography>
        <PeopleIcon style={{ fontSize: 14, marginLeft: "1px",  }} sx={{color: "#999"}} />
    </Box>
}