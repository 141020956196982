import * as React from 'react';
import {
  Popover, Container, Divider, Card, CardContent, Box, Typography
} from '@mui/material'

import InstagramIcon from '@mui/icons-material/Instagram';

export default ({SearchCard, children, sx, counter}) => {
 
    return <Box style={{flex: 1}}>
        <Box backgroundColor="primary.main" width="100%">
            <Container maxWidth="sm" sx={{color:"white", zIndex: 1}}>
                <Typography variant="h4" fontSize="20"  fontWeight="bold" sx={{pt: 15, textAlign: "center"}}>
                    Padelstats.be
                </Typography>
                <Box display="flex" justifyContent="center"alignItems="center" sx={{mt: 0, mb: 0 }}>
                <Box height={5} width={30} alignSelf="center" backgroundColor="white" borderRadius={10} display="flex"></Box>
                </Box>
                <Typography variant="h6" fontWeight="light" sx={{mt: 2, textAlign: "center"}}>
                    Bereken hier je padelklassement voor het volgende seizoen!
                </Typography>
                <Typography variant="h6" fontWeight="light" sx={{mt: 2, textAlign: "center", display:"flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap"}}>
                    Volg ons nu ook op instagram&nbsp;<a style={{fontWeight: "bold", display:"flex", alignItems: "center", justifyContent: "center", color: "inherit", textDecoration: "none"}} href="https://www.instagram.com/padelstats.be/"><InstagramIcon/>padelstats.be</a>!
                </Typography>

                <Box sx={{height: 330, pt: 3}}>
                {SearchCard ? <><SearchCard/>
                {counter != null ? <Typography variant="caption" fontWeight="light" sx={{mt: 2, ml: 2, textAlign: "center"}}>
                    {counter["report"]} opgezochte spelers vandaag
                </Typography>: null}</> : null}
                </Box>
                
            </Container>
        </Box>

    
    <Container maxWidth="sm" sx={{color:"white", ...sx}}>

        {children}

    </Container>

</Box>}

        


