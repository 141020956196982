import { ExpandCircleDown } from '@mui/icons-material'
import * as React from 'react'

const favoritesContext = React.createContext()

function getLocalStorageFavorites() {
    try {
        const localStorageFavorites = localStorage.getItem('favorites')

        if (localStorageFavorites == null) {
            return []
        } else {
            const v = JSON.parse(localStorageFavorites)
            return typeof(v) == "object" ? v : []
        }
    } catch(e) {
        return []
    }
}

function saveLocalStorageFavorites(v) {
    localStorage.setItem('favorites', JSON.stringify(v))
}

export const FavoritesProvider = ({children}) => {

    

    const [data, setData] = React.useState(getLocalStorageFavorites());

    return <favoritesContext.Provider value={{data, setData}}>
        {children}
    </favoritesContext.Provider>
}

export function indexOf(favorites, userId) {
    for (var i = 0; i < favorites.length; ++i) {
        if (userId == favorites[i].userId) {
            return i
        }
    }
    return -1
}

export const useAddFavorite = () => {
    const fav = React.useContext(favoritesContext);

    return (user) => {
        if (indexOf(fav.data, user.userId) == -1 && user.userId !== undefined) {
            fav.data.push(user);
            saveLocalStorageFavorites(fav.data)
            fav.setData(fav.data);
        }
    }
    
}

export const useRemoveFavorite = (userId) => {
    const fav = React.useContext(favoritesContext);

    return (userId) => {
        const index = indexOf(fav.data, userId);
        if (index > -1) {
            fav.data.splice(index, 1);
            saveLocalStorageFavorites(fav.data)
            fav.setData(fav.data);
        }
    }
}

export const useFavorites = () => {
    const fav = React.useContext(favoritesContext);
    return fav.data;
}