import * as React from 'react';
import {
    Popover, Button, Typography, Collapse, CardContent, Select, MenuItem, Box, CardActionArea, Grid
  } from '@mui/material'

  import FilterListIcon from '@mui/icons-material/FilterList';

export default function FilterButton({values, value, setValue, name, sx, ...props}) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => {
        setAnchorEl(null);
      };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

    //const value = filters[filter]

    return (
        <>
        <Button onClick={handleClick} sx={{borderRadius: 2, mx: 0.5, textTransform: "none", py: 0.5, ...sx}} variant="contained" endIcon={<FilterListIcon />} disableElevation {...props}>
                {value === undefined ? name : name + ": " + value}
        </Button>
        <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
            <Box sx={{display: "flex", flexDirection: "column", minWidth: 200}}>
            <Button sx={{textTransform: 'none'}} onClick={() => {handleClose(); setValue(undefined);}}>Geen filter</Button>
            {Object.keys(values).map(v => <Button sx={{textTransform: 'none'}} onClick={() => {handleClose(); setValue(v);}}>{v}</Button>)}
            </Box>
        </Popover>
        </>
    );
}