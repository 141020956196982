
const circColorMap = {50: '#faacee', 100: '#78dbff', 200: '#eb6e74', 300: '#fabe5c', 400: '#b2d62d', 500: '#b0b0b0', 700: '#74cf78', 1000: '#f5b905'}

export default function SeriesIcon({prefix, circuit, ...props}) {
    const length = 15 + 5 * (prefix + circuit).length;

    return <svg width={length} height="20" style={{marginRight: "5px"}} {...props}>
        <rect x="0" y="0" width={length} height="20" fill={circColorMap[circuit]} rx={10} />
        <text x={length/2} y="11" font-size="10" fontWeight="bold" fill="white" text-anchor="middle" alignment-baseline="middle">{prefix ? prefix : ""}{circuit}</text>
    </svg>
}