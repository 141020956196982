import * as React from 'react';

import {
    Card, TextField, InputAdornment, Box, IconButton, Grid, InputBase,
    Typography, Popover, Divider,
  } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';

export default ({data, filter,setFilter}) => {

    const handleSearch = (e) => {
        const v = e.target.value;
        
        setFilter(v);
    }

    const name=data["name"]
    const members =data["members"]

    return <>

        <Box position="relative" sx={{mt: '45px'}}>   
            <Card elevation={0} sx={{
                pt: 3, pb: 3.5, px: 3, 
                borderRadius: 10, 
                border:  (theme) => '20px solid ' + theme.palette.primary.main,
                
                position: 'absolute',
                transform: 'translate(0, calc(-100% + -20px))',
                width: "100%"
                }}>
            <Typography variant="h6" fontWeight="bold" color="primary" component="div">
            {name}
        </Typography>
        <Typography variant="body2" component="div" color="text.primary" fontWeight="light">
            {members ? members.length : 0} leden
        </Typography>
            </Card>
            </Box> 

        <Card sx={{py: 2, mt: 2, mx: 1, mb: 2, borderRadius: 2}} style={{border: "1px solid lightgrey"}} elevation={0}>
        <InputBase
        sx={{ ml: 2, flex: 1 ,
        
            input: {
                "&::placeholder": {    // <----- Add this.
                   opacity: 0.8,
                },
             },
        
        }}
        placeholder="Lid zoeken"
        inputProps={{style: {color: "rgb(100, 100, 100)", textTransform: "capitalize"}}}
        autoComplete='off' 
        value={filter} 
        onChange={handleSearch}
        startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{color: "rgb(100, 100, 100)"}}/>
            </InputAdornment>
          }
        />
        </Card>
    
    </>

}