import * as React from 'react';
import {
  Popover, Container, Divider, Card, Box, Typography, Grid, CardActionArea
} from '@mui/material'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import ClubSearchCard from './components/ClubSearchCard';
import SmallUserCard from './components/SmallUserCard';
import ClubCard from './components/ClubCard';
import News from 'components/News';

import {Adsense} from '@ctrl/react-adsense';
import constants from 'assets/constants'
import PageWrapper from 'components/PageWrapper';
import FilterButton from 'components/FilterButton';

import {useNavigate} from 'react-router-dom';
import {scrollTo, fetchData, stripAccents} from 'assets/utils';

async function fetchCounter() {
  const data = await fetch(constants["BACKEND"] + "/get_counter");
  const content = await data.json();
  
  return content;
}

const pyramids = constants["FILTERS"]["CATEGORY"]
const ranks = constants["FILTERS"]["RANK"]

const filterData = {
    "sub_category": {"values": pyramids, "name":"Categorie"},
    "current_rank": {"values": ranks, "name":"Klassement"},
}

function is_valid_member(member, filters) {
  for (let key in filters) {
    if (filters[key] !== undefined && member["padel"][key] != filterData[key]["values"][filters[key]]) {
      return false;
    }
  }
  return true;
}

function tokenSearch(list, s) {

  const tokens = s.split(" ");

  if (list === undefined || list == null) {
      return []
  }

  return list.filter(user => {
      const n = stripAccents(user["name"]);

      for (var i = 0; i < tokens.length; ++i) {
          const token = tokens[i];
          
          if (!n.includes(stripAccents(token))) {
              return false;
          }
      }

      return true;
  })
}

export default function ClubPage() {
    const [data, setData] = React.useState("content" in window.history.state ? window.history.state["content"] : null);
    const [counter, setCounter] = React.useState(null);
    const navigate = useNavigate();

    const firstRender = React.useMemo(()=> {fetchCounter().then(result => {
      setCounter(result);
      if ("sy" in window.history.state) {
        scrollTo(window.history.state["sy"], ()=>{window.history.pushState({}, "");})
      }
    })}, []);

    React.useEffect(() => {
      if (data != null) {
        setCounter(data["counter"])
      }
    }, [data]);

    const [filter, setFilter] = React.useState("");
    const [maxRender, setMaxRender] = React.useState(100);
    const [memberFilters, setMemberFilters] = React.useState({})

    const maxRenderFiltered = filter.length > 0 ? maxRender - 85 : maxRender;

    const members = data != null ? tokenSearch(data["members"], filter) : []

    const filtered_all = members.filter((item, index) => is_valid_member(item, memberFilters));
    const filtered = filtered_all.filter((item, index) => index < maxRenderFiltered);

    async function fetchClubs(userId) {
      const data = await fetch(constants["BACKEND"] + "/get_club_report/" + userId);
  
      const content = await data.json();

      content["members"].sort((a,b) => b["padel"]["predicted_rank"] -  a["padel"]["predicted_rank"])

      for (var i = 0; i < content["members"].length; ++i) {
          content["members"][i]["nr"] = i+1;
      }

      setData(content);
      setFilter("");
  }

  async function navigateToUser(userId) {
    window.history.pushState({"content": data, "sy": window.scrollY}, "");
    scrollTo(0, () => {
      fetchData(userId, (content) => {navigate("/speler/" + userId, {state: {...content, ts: new Date().getTime()}})})
    });
  }

    //window.history.pushState({"hi": "hi"}, "")

    return <PageWrapper counter={counter} SearchCard = {()=><ClubSearchCard fetchData={fetchClubs}/>}>

        {data != null ? <>
          <ClubCard data={data} filter={filter} setFilter={setFilter}/>

          <Box sx={{mx: 0.5, mb: 1, display: 'flex', justifyContent: "flex-end"}}>
          {Object.keys(filterData).map((filter) => {
                const {name, values} = filterData[filter];
                 
                return <FilterButton key={filter} value={memberFilters[filter]} values={values} setValue={(v) => {
                    memberFilters[filter] = v;
                    setMemberFilters({...memberFilters});
                    //handleFilter(memberFilters);

                    //setFiltered(members.filter((item, index) => index < maxRenderFiltered && is_valid_member(item, memberFilters)))
                }
                } name={name} />
            })}
          </Box>
          
          {filtered.map(member => <SmallUserCard key={member["id"]} navigateToUser={navigateToUser} data={member}/>)}

          {filtered.length < filtered_all.length ? <Card sx={{mx: 0, borderRadius: 2}}>
            <CardActionArea onClick={()=>{setMaxRender(maxRender + 100)}} sx={{px: 1, py: 1, justifyContent: "center"}}>
            <Grid container direction="row" alignItems="center" justifyContent="center">
                <MoreHorizIcon color="text.primary" opacity={0.5}/>
            </Grid>
                
            </CardActionArea>
          </Card> : null}
        
        </> : <News/>}


  </PageWrapper>
}