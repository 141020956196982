import { useTheme } from "@mui/material";


export default function PointsIcon({points, isWp}) {
    const pr = Math.round(100*points)/100;
    const length = 18 + 5 * (pr.toString().length + 3);

    const theme = useTheme();

    const color = isWp ? theme.palette.primary.main : theme.palette.primary.dark;

    return <svg width={length} height="20" style={{marginRight: "5px"}}>
        <rect x="0" y="0" width={length} height="20" fill={color} rx={10} />
        <text x={length/2} y="11" font-size="10" fontWeight="bold" fill="white" text-anchor="middle" alignment-baseline="middle">{pr} {isWp ? "WP" : "VP"}</text>
    </svg>
}