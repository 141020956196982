import * as React from 'react';
import {
    Card, TextField, CardContent, Box, IconButton, Grid, 
    Typography, Popover, Divider, CardActionArea, useTheme, useMediaQuery, Collapse
  } from '@mui/material'

import {WinRatioLine} from 'pages/main/components/CalculationCard';
import { useNavigate } from 'react-router-dom';


export default ({data, navigateToUser}) => {

    const theme = useTheme()
    const navigate = useNavigate();

    const nr = data["nr"]
    const name=data["name"]
    const club=data["club"]
    const rank=data["padel"]["current_rank"]
    const rank_pred=data["padel"]["predicted_rank"]

    //const circuits = Object.keys(data["padel"]["ratios"]);

    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    //const [collapse, setCollapse] = React.useState(false);

    return <Card sx={{borderRadius: 2, mx: 1, border:"0px solid lightgrey"}} elevation={0}>
        <CardActionArea onClick={() => {navigateToUser(data["id"])}} disableRipple>
        <Box sx={{display: "flex", p: 1, px: 1}} justifyContent="space-between" alignItems="center">
            <div>
            <Typography fontSize={14} fontWeight="bold" color="primary" component="div">
                {nr}. {name}
            </Typography>
            <Typography fontSize={12} component="div" color="text.primary">
                {club}
            </Typography>
            </div>
            <div>
                <RankLine tag={smallScreen ? "ENK" : "ENKEL"} rank={rank} rank_pred={rank_pred}/>
            </div>
        </Box>
        </CardActionArea>


        
    </Card>
}

const RankLine = ({rank, rank_pred}) => <Typography fontSize={14} color="text.primary_bold" component="div" textAlign="right" sx={{minWidth: 160}}>
        <Typography display="inline-block" sx={{width: 110, ml: 1, textAlign: "left", whiteSpace: "nowrap"}} fontWeight={"bold"} fontSize={14} color="primary" component="div">P{rank}{rank != rank_pred ? <><Typography display="inline" color="text.primary_bold"> &#8702; </Typography> P{rank_pred}</> : ""}</Typography>
</Typography>