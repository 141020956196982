import {
    LinearProgress, Typography, Stack,
    useTheme
  } from '@mui/material'
import { getFemaleRanks, getMaleRanks } from 'assets/utils';
import {next_rank} from 'assets/utils';
import PercentageBar from 'components/PercentageBar';

function getRanks(isFemale) {
  return isFemale ? getFemaleRanks() : getMaleRanks();
}

function getMarks(data, firstMark, lastMark, theme, isFemale, isRightEdge) {
  const curRank = data["current_rank"];
  const predRank = data["predicted_rank"];
  const predNextRank = next_rank(predRank, isFemale);

  const ranks = getRanks(isFemale);


  var rank = firstMark;
  var marks = [];

  while (rank <= lastMark) {
    const label = "P" + rank;
    const points = rank == predRank ? data["mi"] : (rank == predNextRank ? data["ma"] : null);
    const color = rank <= curRank ? theme.palette.primary.dark : theme.palette.primary.main;

    marks.push({
      rank: rank,
      value: ranks.indexOf(rank),
      label: label, 
      points: points,
      color: color
    });

    const nr = next_rank(rank, isFemale);

    if (nr == rank) {
      break;
    }
    rank = nr;
  }

  if (isRightEdge) {
    marks.push({
      rank: null,
      value: ranks.length,
      label: null, 
      points: null,
      color: theme.palette.primary.main
    });
  }

  return marks;
}

function getProgress(data) {
  const ratio = data["ratios"][data.predicted_rank];

  if (ratio && ratio.v == 1) {
    return (ratio.ratio - ratio.mi) / (ratio.ma - ratio.mi);
  }

  return 0
}

export default ({data, firstMark, lastMark, isFemale, isRightEdge}) => {

    const progress = getProgress(data);

    const theme = useTheme();
    const marks = getMarks(data, firstMark, lastMark, theme, isFemale, isRightEdge);

    const ranks = getRanks(isFemale);

    const marksFrom = marks[0].value;
    const marksTo = marks[marks.length - 1].value;
    const intervalLength = marksTo - marksFrom;

    const s = marks.length > 2 ? 0.75 : 0.50;
  
    return <PercentageBar 
      marks={marks}
      percentage={100 * progress}
      currentValue={ranks.indexOf(data["current_rank"])}
      predictedValue={ranks.indexOf(data["predicted_rank"]) + progress}
      marksFrom={marksFrom - (1 - s)/2 * intervalLength}
      marksTo={marksTo + (1 - s)/2 * intervalLength}
  />
    
  }