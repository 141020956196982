import * as React from 'react';
import {
    Typography, Collapse, Stack, Divider, Card, IconButton, Grid,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Popover, Link, Box, CardActionArea, useTheme
} from '@mui/material'

import InfoIcon from '@mui/icons-material/Info';
import {WinIcon, LoseIcon, WinIconInvalid, LoseIconInvalid, EmptyIcon} from 'assets/Icons';
import PercentageCard from './PercentageCard';
import CircuitLogo from 'components/CircuitLogo';
import {prev_rank, next_rank} from 'assets/utils';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FixMeButton } from './FixMe';

import { styled } from '@mui/material/styles';
import Donut from 'components/Donut';
import { useNavigate } from 'react-router-dom';
import SeriesIcon from 'components/SeriesIcon';
import PointsIcon from 'components/PointsIcon';
import ParticipantsIcon from 'components/ParticipantsIcon';
import { CircuitPredictionCard } from './CircuitPredictionCard';
import StatisticCircle from 'components/StatisticCircle';

const round2 = (el) => {return Math.round(el*100)/100};

export default ({is_single, data, handleFix}) => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const circuits = Object.keys(data["padel"]["ratios"]);
    const notes = [];

    var ii = 0;

    for (var i = 0; i < data["padel"]["notes"].length; i++) {
      const note = data["padel"]["notes"][i];
      if (note["type"] == "ratio1") {
        notes.push(<React.Fragment key={note["c1"]}> {ii > 0 ? <Divider/> : null} <Typography sx={{ p: 2 }} component="div" variant="body2">Je moet minimaal een ratio van 60% hebben in het niveau <SeriesIcon prefix="P" circuit={note["c1"]} style={{transform: 'translateY(5px)'}}/> om in het niveau <SeriesIcon prefix="P" circuit={note["c2"]} style={{transform: 'translateY(5px)'}}/> te kunnen stijgen.</Typography></React.Fragment>)
        ii += 1;
      } else if (note["type"] == "ratio2") {
        notes.push(<React.Fragment key={"dalen_2"}> {ii > 0 ? <Divider/> : null} <Typography sx={{ p: 2 }} component="div" variant="body2">Je daalt 1 klassement door te laag ratio in eigen en lagere reeks.</Typography></React.Fragment>)
        ii += 1;
      } else if (note["type"] == "ratio3") {
        notes.push(<React.Fragment key={note["c1"]}> {ii > 0 ? <Divider/> : null} <Typography sx={{ p: 2 }} component="div" variant="body2">Je moet minimaal <b>{note["target"]}</b> wedstrijden hebben in het niveau <SeriesIcon prefix="P" circuit={note["c1"]} style={{transform: 'translateY(5px)'}} /> voor deze berekening ({note["nm"]}/{note["target"]}).</Typography></React.Fragment>)
        ii += 1;
      }
    }

    /*for (var i = 0; i < circuits.length; i++) {
      const circuit = circuits[i];
      const valid_m = circuit <= cur_rank ? data["padel"]["ratios"][circuit]["nm"] >= 5 : data["padel"]["ratios"][circuit]["nm"] >= 9;
      const valid_r =  circuit <= cur_rank || !(cur_rank in data["padel"]["ratios"]) ? true : (data["padel"]["ratios"][cur_rank]["ratio"] >= 0.6 || data["padel"]["ratios"][cur_rank]["nm"] < 5);

      validDict[circuit] = valid_m && valid_r;
      if (valid_m == false) {
        notes.push(<React.Fragment key={circuit}> {ii > 0 ? <Divider/> : null} <Typography sx={{ p: 2 }} component="div" variant="body2">Je moet minimaal <b>{circuit <= cur_rank ? "5" : "9"}</b> wedstrijden hebben in het niveau <CircuitLogo size={20} circuit={circuit} sx={{mr: 0, transform: 'translateY(-2px)'}} /> voor deze berekening ({ data["padel"]["ratios"][circuit]["nm"]}/{circuit <= cur_rank ? "5" : "9"}).</Typography></React.Fragment>)
        ii += 1;
      } else if (valid_r == false) {
        notes.push(<React.Fragment key={circuit}> {ii > 0 ? <Divider/> : null} <Typography sx={{ p: 2 }} component="div" variant="body2">Je moet minimaal een ratio van 60% hebben in het niveau <CircuitLogo size={20} circuit={cur_rank} sx={{mr: 0, transform: 'translateY(-2px)'}}/> om in het niveau <CircuitLogo size={20} circuit={circuit} sx={{mr: 0, transform: 'translateY(-2px)'}}/> te kunnen stijgen.</Typography></React.Fragment>)
        ii += 1;
      }
      
    }

    const pr = prev_rank(cur_rank, data["gender"] == "V");
    if (validDict[cur_rank] && validDict[pr]  && data["padel"]["ratios"][cur_rank]["ratio"] <= 0.3 && data["padel"]["ratios"][cur_rank]["ratio"] >= 0.2 && data["padel"]["ratios"][pr]["ratio"] <= 0.3) {
      notes.push(<React.Fragment key={"dalen_2"}> {ii > 0 ? <Divider/> : null} <Typography sx={{ p: 2 }} component="div" variant="body2">Je daalt 1 klassement door te laag ratio in eigen en lagere reeks.</Typography></React.Fragment>)
        ii += 1;
    }*/

    return  <>
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"

      sx={{pt: 3}}
    >
      <Typography variant="h6" fontWeight="bold" color="text.primary_bold" component="div">
        Klassementberekening
      </Typography>
      <IconButton aria-label="Example" color="primary" onClick={handleClickOpen}>
      <InfoIcon/>
      </IconButton>
    </Stack>
    
      


      <Typography variant="body" component="div" color="text.primary_bold">
        Huidig klassement: <Typography variant="body" sx={{fontWeight: "bold"}} color="primary">P{data["padel"]["current_rank"]}</Typography>
      </Typography>
      <Typography variant="body" component="div" color="text.primary_bold">
        Voorspelling volgend seizoen: <Typography variant="body" sx={{fontWeight: "bold"}} color="primary">P{data["padel"]["predicted_rank"]}</Typography>
      </Typography>
      
      
      {/*circuits.map((circuit,index) => <WinRatioLine key={circuit} is_female={data["gender"] == "V"} circuit={parseInt(circuit)} valid={data["padel"]["ratios"][circuit]["v"] == 1} data={data["padel"]["ratios"][circuit]} />)*/}
      {circuits.map((circuit,index) => <CircuitPredictionCard key={circuit} isFemale={data["gender"] == "V"} circuit={parseInt(circuit)} data={data["padel"]["ratios"][circuit]} currentRank={data["padel"]["current_rank"]} />)}


      {notes.length > 0 ? <Card sx={{mt: 2, border: '1px solid lightgrey'}} elevation={0}>
            {notes.map(note => note)}
    </Card> : null}

    <div style={{display: "flex", flexDirection: "row", alignItems: "space-between", justifyContent: "space-around", flexWrap: "wrap", marginTop: "40px"}}>
      {/*circuits.filter(circuit => data["padel"]["ratios"][circuit]["nm_stat"] > 0).map(circuit => 
        <div key={circuit} style={{display: "flex", alignContent: "center", justifyContent: "center", paddingTop: 10, width: "50%", minWidth: 200}}>
          <Donut won={data["padel"]["ratios"][circuit]["nw_stat"]} lost={data["padel"]["ratios"][circuit]["nm_stat"] - data["padel"]["ratios"][circuit]["nw_stat"]} name={"P" + circuit}/>
        </div>
        )*/}
        {circuits.filter(circuit => data["padel"]["ratios"][circuit]["nm_stat"] > 0).map(circuit => 
        <div key={circuit} style={{display: "flex", alignContent: "center", justifyContent: "center", width: 160, padding: "10px"}}>
          <StatisticCircle width={160} left={data["padel"]["ratios"][circuit]["nw_stat"]} right={data["padel"]["ratios"][circuit]["nm_stat"] - data["padel"]["ratios"][circuit]["nw_stat"]} statistic={"P" + circuit}/>
        </div>
        )}
    </div>

    <Typography sx={{color: "text.primary", mt: 2}} component="div">
    Iedere gespeelde padelmatch levert winst- (<Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>WP</Typography>) of verliespunten (<Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>VP</Typography>) op voor de categorie.
    Een speler stijgt van klassement indien het ratio winstpunten t.o.v. de verliespunten (<Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>WP &frasl; (WP + VP)</Typography>) voor een bepaalde reeks voldoende hoog is. 
    Verliespunten behaald in lagere reeksen worden bij de verliespunten van je eigen reeks geteld. 
    Hetzelfde principe telt voor winstpunten uit hogere reeksen.
    </Typography>


    
    <Card sx={{borderRadius: 2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, mt: 2}} elevation={0}>

       <Grid sx={{pl: 2, backgroundColor: "primary.main", height: 60}}  container alignItems="center" justifyContent="space-between">
          
            <Stack>
              <Typography variant="body" color="common.white" sx={{fontWeight: "bold"}}>
                Resultaten
              </Typography>
              <Typography variant='caption' color="common.white">
                  {data["name"]} (P{data["padel"]["current_rank"]})
                </Typography>
            </Stack>
          
            <FixMeButton handleFix={handleFix}/>
         
        </Grid>
    </Card>
    <Card sx={{borderRadius: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0, border: '1px solid lightgrey', borderTopWidth: 0}} elevation={0}>

        {data["padel"]["results"].length == 0 ? <Typography component="div" sx={{backgroundColor: "background.paper", padding: 2}}>Geen resultaten gevonden</Typography> : null}

        {data["padel"]["results"].map((el, index) => <ResultLine key={el["title"] + el["subtitle"] + el["score"] + el["series"]} data={el} index={index} />)}

        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Berekening padelklassement</DialogTitle>
            <DialogContent>
            
            <DialogContentText>
            De berekening hier is een voorspelling op basis van de regels zoals vastgelegd met de berekening van het vorige seizoen. Uw werkelijk klassement wordt enkel en alleen bepaald door Padel Vlaanderen en niet door ons.
            Voor meer informatie over het klassement kan men <Link underline="none" sx={{fontWeight: "bold"}} href="https://www.tennisenpadelvlaanderen.be/padel/klassementen-berekening" color="primary">hier</Link> terecht.
            
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Sluiten</Button>
            </DialogActions>
        </Dialog>
        
        

      </Card>
    

    <Typography sx={{color: "text.primary_bold", my: 2}} component="div">
    Het exacte aantal punten die worden toegekend aan een verlies/winst hangt af van hoe ver men in een tornooi geraakt en de categorie waarin gespeeld werd. 
    Naargelang de ronde worden volgende punten toegekend: <br/>

    
    <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline-block", width: 170, mt: 1}}>Poule</Typography>
    <b>WP</b> <Typography display="inline-block" sx={{width: 30, textAlign: "left", fontWeight: "bold", color: "primary.main"}} component = "div">1</Typography>
    <b>VP</b> <Typography display="inline-block" sx={{width: 30, textAlign: "left", fontWeight: "bold", color: "primary.main"}} component = "div">1</Typography><br/>
    <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline-block", width: 170}}>Tabel</Typography>
    <b>WP</b> <Typography display="inline-block" sx={{width: 30, textAlign: "left", fontWeight: "bold", color: "primary.main"}} component = "div">1.5</Typography>
    <b>VP</b> <Typography display="inline-block" sx={{width: 30, textAlign: "left", fontWeight: "bold", color: "primary.main"}} component = "div">0.5</Typography><br/>
    
    Naargelang de categorie worden volgende percentages toegepast:<br/>
    <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline-block", width: 170, mt: 1}}>HEREN of DAMES</Typography>100% van de punten<br/>
    <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline-block", width: 170}}>INTERCLUB</Typography>80% van de punten<br/>
    <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline-block", width: 170}}>GEMENGD (MAN)</Typography>20% van de punten<br/>
    <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline-block", width: 170, mb: 1}}>GEMENGD (VROUW)</Typography>100% van de punten<br/>
    Een dame die in herenreeksen speelt, krijgt een aangepaste score die men <Link underline="none" sx={{fontWeight: "bold"}} href="https://www.tennisenpadelvlaanderen.be/padel/klassementen-berekening" color="primary">hier</Link> kan vinden.<br/>

    Bijkomend zijn de VP nog onderhevig aan de <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>veelspeler reductie</Typography>, een bonus voor spelers die veel matchen spelen, de veelspeler reductie zorgt voor een vermindering van het aantal VP
    en heeft hierdoor een positief effect op de berekende ratio. Er wordt <Typography sx={{fontWeight: "bold", color: "primary.main", display: "inline"}}>1</Typography> <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>VP </Typography>
    reductie toegekend vanaf <Typography sx={{fontWeight: "bold", color: "primary.main", display: "inline"}}>12</Typography> matchen en dit om de <Typography sx={{fontWeight: "bold", color: "primary.main", display: "inline"}}>6</Typography> extra gespeelde matchen op een P-niveau.
    </Typography>
    
  </>
}


const CustomButton = styled(Button)({
  width: '100%',
  boxShadow: 'none',
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
  },
});

const CustomBox = styled(Box)({
  border: "1px solid lightgray",
  borderRadius: 5,
});

const WinLoseIcon = ({type}) => {
  if (type == "W") {
    return  <WinIcon />;
  } else if (type == "L") {
    return <LoseIcon />
  } else if (type == "WI") {
    return  <WinIconInvalid />
  } else if (type == "LI") {
    return <LoseIconInvalid />
  } else if (type == "|") {
    return <Divider orientation={"vertical"} flexItem sx={{my: 0.2}} style={{marginRight: 4, marginLeft: 1}}/>
  } else if (type == "G") {
    return <EmptyIcon/>
  }
}

const ResultLine = ({data, index}) => {

    const [open, setOpen] = React.useState(false);

    const ic = data["IC"] == 1;

    const theme = useTheme()

    return <>
    {index > 0? <Divider/> : null}

    <CustomButton
      onClick={()=>{setOpen(!open)}}
      disableRipple
      component="div"
      sx={{p: 0}}
    >
        <Grid container spacing={0}>
        <Grid item xs={12} sx={{py: 1.5, px: 2}}>
            <Typography variant="body2" component="div" sx={{pr: 0.5, fontWeight: "bold"}} color="text.primary_bold">
                {data["title"]}
            </Typography>
            <Typography variant="caption" component="div"  color="text.secondary" sx={{pb: 0.5}}>
              {data.participants ? [
                <ParticipantsIcon participants={data.participants} color={theme.palette.text.secondary}/>,
                <Typography variant="caption" component="span"  color="text.secondary" sx={{fontSize: 12, marginRight: "3px", marginLeft: "3px"}}>
                  {"\u2022"}
                </Typography>
              ]: null} {data["subtitle"]}
            </Typography>
            {!ic ? <div style={{display: "flex", alignItems: "center"}}>
            {/*<CircuitLogo size={20} sx={{}} circuit={data["circuit"]} />*/}
            <SeriesIcon circuit={data["circuit"]} prefix={data["series"].substring(1,2)}/>

            <Divider orientation={"vertical"} flexItem sx={{my: 0.2}} style={{marginRight: 5}}/>

            <PointsIcon points={Math.round(100 * data["score"][0])/100} isWp/>
            <PointsIcon points={Math.round(100 * data["score"][1])/100}/>
            
            {"WL" in data ? <><Divider orientation={"vertical"} flexItem sx={{my: 0.2}} style={{marginRight: 5}}/> {data["WL"].map((el, index) => <WinLoseIcon key={el + index} type={el}/>)}</> : null}
           
            </div> : (
              <div style={{display: "flex", alignItems: "center", flexDirection: "column", alignItems: "left"}}>
                {Object.keys(data["WL"]).map((circuit, index) => <Box key={circuit} style={{display: "flex", alignItems: "center"}} sx={{mt: index > 0 ? 0.5 : 0}}>
                  <CircuitLogo size={20} circuit={circuit} />
                  <Divider orientation={"vertical"} flexItem sx={{my: 0.2}} style={{marginRight: 5}}/> 
                  {data["WL"][circuit].map((el, index) => <WinLoseIcon key={el + index} type={el}/>)}
                </Box>
                  )}             
              </div>
            )}

            
        </Grid>

        {/*<Grid item xs={3} justifyContent="center" display="flex" sx={{alignItems: "flex-end", flexDirection: "column"}}>
          <Typography sx={{fontWeight: "bold", whiteSpace: "nowrap", color: "text.primary_bold"}} component="div">
            WP <Typography display="inline-block" sx={{width: 50, textAlign: "left", fontWeight: "bold", color: "primary.main"}} component = "div">{Math.round(100 * data["score"][0])/100}</Typography>
          </Typography>
          <Typography sx={{fontWeight: "bold", whiteSpace: "nowrap", color: "text.primary_bold"}} component="div">
            VP <Typography display="inline-block" sx={{width: 50, textAlign: "left", fontWeight: "bold", color: "primary.main"}} component = "div">{Math.round(100 * data["score"][1])/100}</Typography>
          </Typography>
        </Grid>*/}

        </Grid>

        </CustomButton>
        

        <Collapse in={open} unmountOnExit>
        
        <Box sx={{display: "flex", justifyContent: "center", flexDirection: "column", px: 4}}>
        {"matches" in data ? <MatchResults matches={data["matches"]} /> : null}
        <TableContainer component={CustomBox} sx={{mt: 1, mb: 2}}>
          <Table sx={{p: 0}} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{p: 1}}></TableCell>
                {data["subscores"].map((el, index) => <TableCell key={el["title"]} align="right" sx={{fontWeight: "bold", color: "text.primary_bold"}}>{el["title"]}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              
                <TableRow
                  key="1"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{p: 1, fontWeight: "bold", color: "text.primary_bold"}}>
                    WP
                  </TableCell>
                  {data["subscores"].map((el, index) => <TableCell key={el["title"]} align="right" sx={{fontWeight: "bold", color: (theme) => theme.palette.primary.main}}>{el["sl"] !== undefined ? el["sl"][0] : el["score"][0]}</TableCell>)}
                </TableRow>
                <TableRow
                  key="2"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell  sx={{p: 1, fontWeight: "bold", color: "text.primary_bold"}}>
                    VP
                  </TableCell>
                  {data["subscores"].map((el, index) => <TableCell key={el["title"]} align="right" sx={{fontWeight: "bold", color: (theme) => theme.palette.primary.main}}>{el["sl"] !== undefined ? el["sl"][1] : el["score"][1]}</TableCell>)}
                </TableRow>
              
            </TableBody>
          </Table>
        </TableContainer>

        {data["url"] ? <Button sx={{display: "flex", alignSelf: "center", mb: 2, mt: -1}} onClick={() => window.open(data["url"])}>Naar tabel</Button> : null}
        </Box>
    </Collapse>

    
    
  </>
}

const CategoryLine = ({index, data, circuit}) => ( <>

  {index > 0 ? <Divider/> : null}

  <Box sx={{position: "relative", alignItems: 'center', pl: 2, py: 1, display: 'flex', flexDirection: "row", justifyContent: "space-between"}}>
    <CircuitLogo size={40} circuit={circuit} />
    <Box sx={{display: "flex", alignItems: "flex-end", flexDirection: "column"}}>
      <Typography sx={{fontWeight: "bold", color: "text.primary_bold"}}>
        WP <Typography display="inline-block" sx={{width: 50, textAlign: "left", fontWeight: "bold", color: "primary.main"}} component = "div">{Math.round(100 * data["wp"])/100}</Typography>
      </Typography>
      <Typography sx={{fontWeight: "bold", color: "text.primary_bold"}}>
        VP <Typography display="inline-block" sx={{width: 50, textAlign: "left", fontWeight: "bold", color: "primary.main"}} component = "div">{Math.round(100 * data["vp"])/100}</Typography>
      </Typography>
      <Typography sx={{fontWeight: "bold", color: "text.primary_bold"}}>
        R <Typography display="inline-block" sx={{width: 50, textAlign: "left", fontWeight: "bold", color: "primary.main"}} component = "div">{Math.round(100*data["ratio"])}%</Typography>
      </Typography>
    </Box>
  </Box>

  </>
)

export const WinRatioLine = ({circuit, data, is_female, valid}) => {
  const height = 40;
  const tagHeight = 26;

  const ratio = data["ratio"]
  const mi = data["mi"]
  const ma = data["ma"]
  const wp = data["wp"]
  const vp = data["vp"]

  const [open, setOpen] = React.useState(false);

  return <CardActionArea onClick={()=>{setOpen(!open)}} disableRipple><Box sx={{display: 'flex', flexDirection: "column", position: "relative", mt: 3, opacity: valid ? 1 : 0.4}} style={{marginBottom: "40px"}}>
      <Box sx={{overflow: "hidden", borderBottomRightRadius: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5, border: (theme) => '2px solid ' + theme.palette.primary.main, backgroundColor: "primary.light"}}>
      <Box sx={{display: 'flex', flexDirection: "row", height: height, position: "relative", width: "100%"}}>
          <Box sx={{backgroundColor:"#d5a5fa", flex: ratio}}>
              
          </Box>
          <Box sx={{backgroundColor: '#ead4fa', opacity: 1, flex: 1-ratio}}>
              
          </Box>
      </Box>
      
      <Collapse in={open}>
      <Box sx={{color: "white", backgroundColor: "primary.main", px: 1.5, py: 1}}>
        <Typography sx={{fontWeight: "bold", fontSize: 12}} component="div">
            <Typography sx={{width: 110, display: "inline-block", fontWeight: "inherit", fontSize: 'inherit'}}>Aantal wedstrijden</Typography> {data["nm"]}
        </Typography>
        <Typography sx={{fontWeight: "bold", fontSize: 12}} component="div">
            <Typography sx={{width: 20, display: "inline-block", fontWeight: "inherit", fontSize: 'inherit'}}>WP</Typography> {round2(data["wp"])} = {data["wpb"].map((el, index) => index > 0 ? (el >= 0 ? " + " : " - ") + round2(Math.abs(el)) : round2(Math.abs(el)))}
        </Typography>
        <Typography sx={{fontWeight: "bold", fontSize: 12}} component="div">
            <Typography sx={{width: 20, display: "inline-block", fontWeight: "inherit", fontSize: 'inherit'}}>VP</Typography> {round2(data["vp"])} = {data["vpb"].map((el, index) => index > 0 ? (el >= 0 ? " + " : " - ") + round2(Math.abs(el)) : round2(Math.abs(el)))}
        </Typography>
        
      </Box>
      </Collapse>
      </Box>
      
      {prev_rank(circuit, is_female) == circuit ? null : <InfoTag fraction={mi} height={height} tag={"P" + circuit} reached={ratio > mi}/>}
      {next_rank(circuit, is_female) == circuit ? null : <InfoTag fraction={ma} height={height} tag={"P" + next_rank(circuit, is_female)} reached={ratio > ma}/>}

      

      <Typography component="div" sx={{fontWeight: "bold", fontSize: 12, position: "absolute", left: 0, bottom: 0, height: tagHeight, borderBottomRightRadius: 5, borderBottomLeftRadius: 5, lineHeight: tagHeight + 'px', pr: 1, pl: 1, display: "flex", alignItems: "center", backgroundColor: "primary.main"}} style={{marginBottom: '-' + (tagHeight-2) + 'px'}} >
      <CircuitLogo size={20} circuit={circuit} sx={{mr: 1}} /> {Math.round(100*wp)/100} &frasl; {Math.round(100*(vp+wp))/100} = {Math.round(1000*ratio)/10}%
      <InfoIcon sx={{color: "white", fontSize: 18, ml: 0.5, opacity: 0.8}}/>
      </Typography>
  </Box>
  </CardActionArea>
}

const InfoTag = ({fraction, height, tag, reached}) => {

  const tagColor = 'primary.main'//"rgba(160, 10, 247, " + f + ")"

  const tagHeight = 16;
  const padding = -1;

  const ml = 'calc(2px + ' + fraction + ' * (100% - 4px))';

  return <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", transform: 'translate(-50%,-' + (tagHeight + padding) + 'px)', height: height + tagHeight + padding, position: "absolute", ml: ml}}>
      <Box sx={{height: tagHeight, px: 0.5, backgroundColor: tagColor, textAlign: "center", lineHeight: tagHeight + 'px', pt: 0.2, fontSize: 9, borderTopLeftRadius: 5, borderTopRightRadius: 5, fontWeight: "bold"}}>
      {Math.round(100*fraction)}% {tag}
      </Box>
      <Box sx={{width: 0, border: (theme) => "0.5px solid " + theme.palette.primary.main, marginTop: '0px', marginBottom: '-2px', height: height + 2, backgroundColor: 'primary.main'}}/>
  </Box>
}


const MatchResults = ({matches}) => {
  return <CustomBox sx={{mt: 2}}>
    {matches.map(el => <MatchResult key={el.round} match={el}/>)}
  </CustomBox>

}

const MatchResult = ({match}) => {
    const theme = useTheme();

    const navigate = useNavigate();

    const num_opponents = match["opponents"].length

    return <Box p={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ borderBottom: "1px solid lightgray" ,'&:last-child': { borderBottom: 0 } }}>
    <Box display="flex" flexDirection="column" lineHeight={1.4}>
    {match["opponents"].map(opponent => <Typography key={opponent["user_id"]} variant="caption" fontWeight="bold" color="primary.main" sx={{cursor: "pointer"}} onClick={() => navigate('/speler/' + opponent.user_id)}>
      {opponent["name"]}{num_opponents > 1 ? <b style={{color: theme.palette.text.primary_bold}}> • P{opponent.rank}</b> : null}
    </Typography>)}
    
    <Typography variant="caption" color="text.secondary" lineHeight={1.4}>
      {num_opponents == 1 ? <b style={{color: theme.palette.text.primary_bold}}>P{match.opponents[0].rank} • </b> : null}{match.round}
    </Typography>
    </Box>
    <Box display="flex" alignItems="center" flexDirection="row" justifyContent="center">
      <Typography variant="caption" color="text.secondary" fontWeight="bold" px={1} pt='1px'>
        {match.score}
      </Typography>
      {match["did_win"] == 1 ? <WinIcon/> : <LoseIcon/>}
    </Box>
  </Box>
}