import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  useTheme, Box, Typography
} from '@mui/material'
import FilterButton from 'components/FilterButton';
import constants from 'assets/constants'
import { useChartData } from 'assets/utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = (sum) => ({
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
        callbacks: {
            title: (v) => v[0]["label"],
            footer: (v) => "Totaal: " + sum
        }
    }
  },
  interaction: {
    intersect: false,
    mode: 'index',
  },
  scales: {
    y: {
        maxRotation: 0,
      display: false
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
      //  maxRotation: 0
      callback: function(val, index) {
        // Hide the label of every 2nd dataset
        return 'P' + this.getLabelForValue(val);
      },
      }
    }
  },
});

//const labels = ['P50', 'P100', 'P200', 'P300', 'P400', 'P500', 'P700', 'P1000'];
const labels = Object.values(constants["FILTERS"]["RANK"]);
const pyramids = constants["FILTERS"]["CATEGORY"]
export default function ActivePlayersChart() {

  const theme = useTheme()
  const data = useChartData()["active_players"];

  const [filter, setFilter] = React.useState(undefined);
  const filterTag = pyramids[filter];

  const chartData = {
    labels: data ? Object.keys(data[filterTag ? filterTag:"base"]) : labels,
    datasets: [
      {
        label: filter ? filter : "Spelers",
        data: data ? Object.values(data[filterTag ? filterTag:"base"]) : [],
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
      },
    ],
  
  };

  const sum = data ? Object.values(data[filterTag ? filterTag:"base"]).reduce((a,v) =>  a = a + parseInt(v) , 0 ) : 0;

  return <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", pt: 2}}>
        <FilterButton variant="text" sx={{fontWeight: "700", letterSpacing: '0.0075em', alignSelf: "flex-start", pr: 1, pl: 0, mx: 0, fontSize: "1.25rem", color: theme => theme.palette.text.primary_bold}} name="Actieve spelers" values={pyramids} value={filter} setValue={setFilter} />

        <Typography variant="body" color="text.primary_bold" component="div" sx={{mb: 2}} >
            Actieve spelers zijn alle spelers met een padelklassement. In totaal zijn er <b>{data ? Object.values(data["base"]).reduce((a,v) =>  a = a + parseInt(v) , 0 ) : ""}</b> actieve Vlaamse spelers. 
        </Typography>

    <Bar options={options(sum)} data={chartData} />
      
  </Box>;
}
