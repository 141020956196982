import * as React from 'react';
import {
    Grid, Card, TextField, CardContent, Box, 
    Typography, Divider,
    CircularProgress,
    CardActionArea,
  } from '@mui/material'

import SearchCard from "components/SearchCard";
import constants from 'assets/constants';

import { stripAccents } from 'assets/utils';

async function fetchClubList(s) {
    const data = await fetch(constants["BACKEND"] + "/list_clubs?s=" + s);
    const content = await data.json();
    
    return content;
}

function tokenSearch(list, s) {
    const tokens = s.split(" ");

    if (list === undefined || list == null) {
        return []
    }

    return list.filter(user => {
        const c = stripAccents(user["name"].replace(".", ""));

        for (var i = 0; i < tokens.length; ++i) {
            const token = tokens[i];
            
            if (!c.includes(stripAccents(token))) {
                return false;
            }
        }

        return true;
    })
}

export default function ClubSearchCard ({fetchData}) {

    return <SearchCard
        fetchData={fetchData}
        fetchList={fetchClubList}
        searchMethod={tokenSearch}
        MIN_LENGTH = {3}
        generalText={{"search": "club", "searchField": "Clubnaam"}}
        listItem={(index, el, handleClick) => 
            <React.Fragment key={el["name"] + el["club_id"]}>
            {index > 0? <Divider/> : null}
            <CardActionArea onClick={()=>{handleClick(el)}} sx={{pl: 2, pt: 2, pb: 2}}>
            <Typography variant="body2" sx={{fontWeight: "bold"}} color="text.primary_bold">{el["name"]}</Typography>
            </CardActionArea>

            </React.Fragment>
        }

    />
}