import { Box, useTheme } from "@mui/material";
import { Line } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Chart, PointElement, LineElement} from 'chart.js';

Chart.register(PointElement);
Chart.register(LineElement)

export default function HistoryChart({history}) {
    const theme = useTheme()

    const ranks = history.map(el => el["r"]);

    const options = {
        layout: {
            padding: {
                top: 0, // Adjust this value to add more or less padding to the top
                right: 40,
                bottom: 0,
                left: 40
            }
        },
        responsive: true,
        aspectRatio: 2,
        plugins: {
            legend: {
            display: false,
            position: "bottom"
            },
            datalabels: {
                backgroundColor: function(context) {
                    return context.dataset.backgroundColor;
                },
                borderRadius: 4,
                color: 'white',
                font: {
                    weight: 'bold'
                },
                formatter: (value, context) => "P" + value,
                padding: 6,
            }
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
        scales: {
            y: {
                maxRotation: 0,
                display: false,
                min: Math.min(...ranks.filter(el => el !== null)) - 50,
                max: Math.max(...ranks.filter(el => el !== null)) + 50,
            },
            x: {
            grid: {
                display: false,
            },
            //ticks: {
            //  maxRotation: 0
            //}
            }
        },
        };
        
    const chartData = {
        labels: history.map(el => el["l"]),
        datasets: [
        {
            label: "Padel",
            data: ranks,
            backgroundColor: history.map(el => el["p"] ? "#ddb0ff" : theme.palette.primary.main),
            borderColor: theme.palette.primary.main,
            borderRadius: 5,
        },
        ],
    
    };
            
    return <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", pt: 2}}>
        <Line options={options} data={chartData} plugins={[ChartDataLabels]} />
    </Box>;
}