import * as React from 'react';
import {
  Container, Link, Card, Box, Typography, Collapse, CardActionArea
} from '@mui/material'

import PageWrapper from 'components/PageWrapper';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function FaqPage() {
    
  
    return <PageWrapper>
            <Box position="relative" sx={{mt: '25px', pb: 2}}>   
            <Card elevation={0} sx={{
                pt: 3, pb: 3.5, px: 3, 
                borderRadius: 10, 
                border:  (theme) => '20px solid ' + theme.palette.primary.main,
                
                position: 'absolute',
                transform: 'translate(0, calc(-100% + 0px))',
                width: "100%"
                }}>
            <Typography variant="h6" fontWeight="bold" color="primary" component="div">
                FAQ
            </Typography>
            

            <Typography variant="body" fontWeight="light" component="div" >
                Veelgestelde vragen
            </Typography>
            </Card>
            </Box>  

            <FAQ title="Hoe worden de ratio's berekend">
                <Typography sx={{color: "text.primary", mb: 3}}>
                    Iedere gespeelde padelmatch levert winst- (<Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>WP</Typography>) of verliespunten (<Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>VP</Typography>) op voor de categorie.
                    Een speler stijgt van klassement indien het ratio winstpunten t.o.v. de verliespunten (<Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>WP &frasl; (WP + VP)</Typography>) voor een bepaalde reeks voldoende hoog is.
                    Verliespunten behaald in lagere reeksen worden bij de verliespunten van je eigen reeks geteld.
                    Hetzelfde principe telt voor winstpunten uit hogere reeksen.
                    Het exacte aantal punten die worden toegekend aan een verlies/winst hangt af van hoe ver men in een tornooi geraakt en de categorie waarin gespeeld werd.
                    Naargelang de ronde worden volgende punten toegekend: <br/>


                    <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline-block", width: 170, mt: 1}}>Poule</Typography>
                    <b>WP</b> <Typography display="inline-block" sx={{width: 30, textAlign: "left", fontWeight: "bold", color: "primary.main"}} component = "div">1</Typography>
                    <b>VP</b> <Typography display="inline-block" sx={{width: 30, textAlign: "left", fontWeight: "bold", color: "primary.main"}} component = "div">1</Typography><br/>
                    <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline-block", width: 170}}>Tabel</Typography>
                    <b>WP</b> <Typography display="inline-block" sx={{width: 30, textAlign: "left", fontWeight: "bold", color: "primary.main"}} component = "div">1.5</Typography>
                    <b>VP</b> <Typography display="inline-block" sx={{width: 30, textAlign: "left", fontWeight: "bold", color: "primary.main"}} component = "div">0.5</Typography><br/>

                    Naargelang de categorie worden volgende percentages toegepast:<br/>
                    <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline-block", width: 170, mt: 1}}>HEREN of DAMES</Typography>100% van de punten<br/>
                    <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline-block", width: 170}}>INTERCLUB</Typography>80% van de punten<br/>
                    <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline-block", width: 170}}>GEMENGD (MAN)</Typography>20% van de punten<br/>
                    <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline-block", width: 170, mb: 1}}>GEMENGD (VROUW)</Typography>100% van de punten<br/>
                    Een dame die in herenreeksen speelt, krijgt een aangepaste score die men <Link underline="none" sx={{fontWeight: "bold"}} href="https://www.tennisenpadelvlaanderen.be/padel/klassementen-berekening" color="primary">hier</Link> kan vinden.<br/>

                    Bovendien is er nog een extra correctie : de <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>veelspeler reductie</Typography>, een bonus voor spelers die veel matchen spelen, de veelspeler reductie zorgt voor een vermindering van het aantal VP
                    en heeft hierdoor een positief effect op de berekende ratio. Er wordt <Typography sx={{fontWeight: "bold", color: "primary.main", display: "inline"}}>1</Typography> <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>VP </Typography>
                    reductie toegekend vanaf <Typography sx={{fontWeight: "bold", color: "primary.main", display: "inline"}}>12</Typography> matchen en dit om de <Typography sx={{fontWeight: "bold", color: "primary.main", display: "inline"}}>6</Typography> extra gespeelde matchen op een P-niveau.
                </Typography>
            </FAQ>

            <FAQ title="Houden jullie rekening met de veelspeler reductie?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                    Uiteraard doen we dat, hou er wel rekening mee dat het minimum aantal matchen nodig voor de reductie evenals het interval om de hoeveel matchen je een extra reductie punt krijgt elk seizoen kan wijzigen
                    en dus mogelijks een effect op je klassementvoorspelling heeft.
                </Typography>
            </FAQ>

            <FAQ title="Hoe komt het dat mijn ratio daalt terwijl ik win?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                    Ongetwijfeld één van de meest gestelde vragen. Het is goed mogelijk dat je in een tornooi winstmatchen hebt en toch zakt in ratio.
                    Hoe komt dit ? De reden is de formule WP/(WP+VP), stel je hebt een ratio van 75% doordat je 3 WP en 1 VP hebt : ratio = 3/(3+1) = 75%.
                    Vervolgens win je in een tornooi 2 van de 3 poule matchen. Goed gespeeld toch ? Jazeker, maar toch daalt je ratio = (3 + 2) / (3+1 + 3)  = 71,4%.
                </Typography>
            </FAQ>

            <FAQ title="Wat bij Walk-Over (WO), Forfait (FF) of Opgave?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                    Tot eind 2023 was het zo dat een WO/FF of Opgave 0/0 ervoor zorgde dat niemand van de 2 duo’s verlies of winstpunten voor het klassement kreeg.
                    Vanaf 2024 is deze regel aangepast en worden WO/FF/Opgave wel degelijk meegerekend voor het klassement. Dus ook bij WO (steeds gewettigd) adhv bijvoorbeeld een medisch attest.
                    Deze beslissing werd gecommuniceerd naar de spelers via email (zie 3de sectie <Link underline="none" sx={{fontWeight: "bold"}} href="https://tennisvlaanderen.m9.mailplus.nl/archief/mailing-4463875.html" color="primary">&#39;Gevolgen van terugtrekking na loting in de klassementsberekening&#39;</Link>).
                    De duiding die wordt gegeven mbt deze regel is begrijpelijk : &quot;deze regel is er gekomen omdat er veel misbruik was rond WO/FF ifv klassement. Duo’s die WO’s geven omdat
                    ze in een moeilijke poule geloot zijn of in eindrondes tegen een moeilijk duo uitkomen. Door deze WO vermeden ze een verlieswedstrijd. Dit kan nu niet meer.&quot; aldus Padelvlaanderen.
                    Er zou wel een uitzondering bestaan wanneer een WO gegeven wordt met als reden ’extreem weer’, dan zouden er geen VP/WP toegekend worden als de match niet herpland kan worden.
                    Dit is problematisch voor onze berekening aangezien de reden niet zichtbaar is, er staat enkel ’Walk-Over’.
                    We bekijken of we daar een werkbare workaround voor vinden.
                </Typography>
            </FAQ>

            <FAQ title="Hoe accuraat is de klassementvoorspelling?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                De accuraatheid van de voorspellingen is sterk afhankelijk van het aantal wijzigingen die gebeuren t.o.v. de berekening van vorig seizoen. Teneinde de klassmentpiramide wat in balans te houden, zal Padel Vlaanderen bij
                het toekennen van de klassementen de te behalen ratio % aanpassen zodat grosso modo elke volgende P-schijf de helft van het aantal spelers van de vorige P-schijf bevat. Behalve het verschuiven van grenzen kunnnen ook de correctiefactoren
                zoals de weging van de eindronde WP en VP wijzigen, alsook de weging van interclubmatchen. Ook de veelspeler reductie kan onderhevig zijn aan wijzigingen. De voorspellingen zijn dus louter informatief en op geen enkele manier bindend,
                alleen Padel Vlaanderen beslist over het definitieve klassement van de spelers.
                </Typography>
            </FAQ>

            <FAQ title="Er ontbreekt een wedstrijd/tornooi in mijn resultaten?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                Indien je denkt dat er data ontbreekt in je overzicht, 
                stuur ons de reeks in een berichtje (via <Link href="mailto:info.tennisstats@gmail.com" underline="none">
                info.tennisstats@gmail.com
                </Link>). We zorgen dat dit zo snel mogelijk in orde komt.
                </Typography>
            </FAQ>

            <FAQ title="Ik heb een wedstrijd gespeeld, maar vind mijn naam niet terug in de lijst?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                Het kan zijn dat je jezelf niet kan vinden in onze zoekfunctie. Indien zo, contacteer ons via <Link href="mailto:info.tennisstats@gmail.com" underline="none">
                info.tennisstats@gmail.com
                </Link> en we zorgen dat je resultaten zo snel mogelijk zichtbaar zijn.
                </Typography>
            </FAQ>

            <FAQ title="Wat met privacy?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                Wij herverpakken enkel sportresultaten van wedstrijden/tornooien die reeds publiek beschikbaar zijn op het internet.
                De resultaten worden uitsluitend gebruikt met als doeleinde de spelers inzicht te geven in de resultaten van het lopende seizoen en zo goed als mogelijk te begeleiden
                in de complexiteit van de winstratio berekening met als extra een voorspelling of schatting van hun volgende klassement.
                Staan je resultaten ongewenst op onze site? Email ons via <Link href="mailto:info.tennisstats@gmail.com" underline="none">info.tennisstats@gmail.com
                </Link> met de vraag verwijderd te worden. Zie ook onze <Link href="about">privacyverklaring</Link> voor meer info.
                </Typography>
            </FAQ>

    </PageWrapper>
}

const FAQ = ({title, children}) => {
    const [open, setOpen] = React.useState(false);



    return <>

    <Box sx={{backgroundColor: "primary.main", borderRadius: 2, mb: 1}}>
        <CardActionArea sx={{flexDirection: "row", display: "flex", py: 1, px: 2, justifyContent: "flex-start"}} onClick={()=>{setOpen(!open)}}>
            <ChevronRightIcon style={{ transform: open ? "rotate(90deg)" : "rotate(0)", transition: "all 0.1s linear" }}/>
            
            <Typography sx={{px: 1}} fontWeight="bold">{title}</Typography>
        </CardActionArea>
    </Box>

    <Collapse in={open}>
        {children}
    </Collapse>

</>}