import { useTheme } from "@mui/material"

export default function SeasonBar({marks, from, to, selectedFrom, selectedTo}) {
    const theme = useTheme()

    const calculatePosition = (value) => {
        return (value - from) / (to - from) * 100;
    }

    return <svg style={{width: "100%", marginLeft: '0%', height: "24px", overflow: 'visible', padding: '0px 0px', marginTop: '5px'}}>
        <defs>
            <clipPath id="clip">
            <rect id="clipRect" x={calculatePosition(selectedFrom) + "%"} y="0px" width={calculatePosition(selectedTo) - calculatePosition(selectedFrom) + "%"} height="14px" />
            </clipPath>
        </defs>

        <rect rx="7px" width="100%" y="0px" height="14px" fill="#e0e0e0" />
        <rect rx="7px" y="0px"
                width="100%"
                height="14px"
                clip-path="url(#clip)"
                fill={theme.palette.primary.main}
                />
        
        {marks.map((el, index) => <line key={index} x1={calculatePosition(el.value) + "%"} y1="0" x2={calculatePosition(el.value) + "%"} y2="14" stroke={"white"} strokeWidth={1} />)}
        {marks.map((el, index) => <text key={index} x={(calculatePosition(el.value) + 1) + "%"} y="8px" dominant-baseline="middle" text-anchor="left" fontWeight="bold" fontSize={12} fill="white">{el.label}</text>)}
        
        </svg>
}